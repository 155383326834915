export default {
   newsLetterList: [],
   DeleteList : null,
   editList : null,

   NewsLetterPdfs: [],
   showDeleteNewsletter: false,
   hideDeleteNewsletter: false,

   showAddNewsletter: false,
   hideAddNewsletter: false,

   showEditNewsletter: false,
   hideEditNewsletter: false,

   editNewsletterId: null,

   editNewsletter :{
      month:'',
      year:'',
      newsletter_pdf:'',
      front_cover_image:'',
      back_cover_image:'',
      images:'',
      s_token: process.env.VUE_APP_SSO_PUBLIC_KEY,
      c_token: process.env.VUE_APP_JIPPA_PUBLIC_KEY
   },
} 