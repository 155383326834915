const getMainHeaderLinks = (state) => {
	return state.headers.mainHeaderLinks
}
const getCityData = (state) => {
	return state.cities
}
const getCountryData = (state) => {
	return state.countries
}
export default {
    getMainHeaderLinks,
    getCityData,
    getCountryData,
    getIsAuth() {
		return state.isAuth
	},
}