export default {
   galleryList: [],
   showGalleryDeleteModal: false,
   hideGalleryDeleteModal: false,
   presentGalleryIdDelete: null,
   galleryById: null,
   editIdGallery: [],
   updateImageGalleryIdDelete: null,
   showImageGalleryDeleteModal: false,
   hideImageGalleryDeleteModal: false,

} 