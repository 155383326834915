export const storeLanguageName = ({ commit }, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + `api/save-imported-language-name`,payload).then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            return response.data.success;
        }
        return false;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.isDisable = false;
        context.btnName = 'CALER-h4';
        return false;
    })
}
export const getLanguageCodeList = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/imported-language-code-list`).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
}

export const getLanguageNameBySlug = ({ commit }, payload) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/get-language-name-by-slag/`+payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        return false;
    })
}