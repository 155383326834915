export default {
	user: {
		token: null,
		authenticated: false,
		data: null,
		roleName: null,
		roleSlug: null,
	},
	showTab: true,
	roleList: [],
	roleListCopy: [],
	roleList:{
        data: null,
        links: null,
        meta: null
    },
	permissionCategoryList: [],
	showDeleteRole: false,
    hideDeleteRole: false,
    currentIdBeingDeleted: null,
    editRole: [],
}
