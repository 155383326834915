export const getNewsLetterList = (state) => {
	return state.newsLetterList 
}
export const getShowDeleteNewsletter = (state) => {
	return state.showDeleteNewsletter
}
export const hideDeleteNewsletter = (state) => {
	return state.hideDeleteNewsletter
}

export const getshowAddNewsletter = (state) => {
	return state.showAddNewsletter
}
export const hideAddNewsletter = (state) => {
	return state.hideAddNewsletter
}

export const getshowEditNewsletter = (state) => {
	return state.showEditNewsletter
}
export const hideEditNewsletter = (state) => {
	return state.hideEditNewsletter
}

export const  DeleteList = (state)=> {
	return state.DeleteList
}
export const  editList = (state)=> {
	return state.editList
}
export const  getNewsLetterPdfs = (state)=> {
	return state.NewsLetterPdfs
}


export const getDataBeingEditForNewsletter = (state) => {
	return state.editNewsletter
}
export const getEditNewsletterId = (state) => {
	return state.editNewsletterId
}

export const getUpdateImageNewsletterIdDelete = (state) => {
      return state.showImageNewsletterDeleteModal
}
export const hideImageNewsletterDeleteModal = (state) => {
      return state.hideImageNewsletterDeleteModal
}