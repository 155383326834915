export default [
	{
		path: '/my-profile',
		component: () => import('../components/ProfileParent.vue'),
		meta: {	
			guest: false,
			needsAuth: true		
		},
		children:[
			{
				path: '/my-profile',
				component: () => import('../components/MyProfile.vue'),
				name: 'my-profile',
			},

			{
				path: '/edit-profile',
				component: () => import('../components/EditProfile.vue'),
				name: 'edit-profile',
			}
		]
	},
	{
		path: '/edit-profile/change-password',
		component: () => import('../components/partial/ChangePassword.vue'),
		meta: {	
			guest: false,
			needsAuth: true		
		},
	},

	
]
