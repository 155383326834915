export default [

	 {
        path: '/settings/general',
        component: () => import('../components/Settings.vue'),
        meta: {
        	guest: false,
			needsAuth: true
        },
        children: [
            {
                path: '/settings/general',
                component: () => import('../components/partials/General.vue'),
                name: 'settings-general',
            },

            {
                path: '/settings/dashboard',
                component: () => import('../components/partials/Dashboard.vue'),
                name: 'settings-dashboard',
            },
            {
                path: '/settings/auto-email-dispatch',
                component: () => import('../components/partials/AutoEmailDispatch.vue'),
                name: 'auto-email-dispatch',
            },
            
            {
                path: '/settings/finance-and-audit',
                component: () => import('../components/partials/FinanceAudit.vue'),
                name: 'finance-audit',
            },
            {
                path: '/settings/file-server',
                component: () => import('../components/partials/FileServer.vue'),
                name: 'file-server',
            },
            {
                path: '/settings/discount-promotion-plan',
                component: () => import('../components/partials/DiscountPromotionParent.vue'),
                children:[
                    {
                       path: '/settings/discount-promotion-plan',
                        component: () => import('../components/partials/DiscountPromotionPlan.vue'),
                        name: 'discount-promotion-plan', 
                    },
                    {
                       path: '/settings/add-promotion-plan',
                        component: () => import('../components/partials/AddPromotionPlan.vue'),
                        name: 'add-promotion-plan', 
                    },
                    {
                       path: '/settings/view-promotion-plan/:id',
                        component: () => import('../components/partials/ViewPromotionPlan.vue'),
                        name: 'view-promotion-plan', 
                    },
                    {
                       path: '/settings/edit-promotion-plan/:id',
                        component: () => import('../components/partials/EditPromotionPlan.vue'),
                        name: 'edit-promotion-plan', 
                    },
                ]
            },
            {
                path: '/settings/contract-agreements',
                component: () => import('../components/partials/ContractAgreements.vue'),
                name: 'contract-agreements',
            },

            {
                path: '/settings/slack-integration',
                component: () => import('../components/partials/SlackIntegration.vue'),
                name: 'slack-integration',
            },
            
            
            {
                path: '/settings/slack-function',
                component: () => import('../components/partials/SlackFunctionParent.vue'),
                children:[
                    {
                        path: '/settings/slack-function',
                        component: () => import('../components/partials/SlackFunction.vue'),
                        name: 'slack-function',
                    },
                    {
                        path: '/settings/slack-function-add',
                        component: () => import('../components/partials/SlackFunctionAdd.vue'),
                        name: 'settings-slack-add',
                    },
                    {
                        path: '/settings/slack-function-view',
                        component: () => import('../components/partials/SlackFunctionView.vue'),
                        name: 'settings-slack-view',
                    },
                    {
                        path: '/settings/slack-function-edit',
                        component: () => import('../components/partials/SlackFunctionEdit.vue'),
                        name: 'settings-slack-edit',
                    },
                ]
            },
            
            
        ]
    },

]
