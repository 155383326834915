export const getShowAddNewAgreement = (state) => {
	return state.showAddNewAgreement
}
export const hideAddNewAgreement = (state) => {
	return state.hideAddNewAgreement
}

// ~~~EditAgreement
export const getShowEditAgreement = (state) => {
	return state.showEditAgreement
}
export const hideEditAgreement = (state) => {
	return state.hideEditAgreement
}
// ~~~~

export const getShowDeleteModel = (state) => {
	return state.showDeleteModel
}
export const hideDeleteModel = (state) => {
	return state.hideDeleteModel
}

// model add payment
export const getShowAddPayment = (state) => {
	return state.showAddPayment
}
export const hideAddPayment = (state) => {
	return state.hideAddPayment
}
// ~~~~~~~

// delete payment
export const getShowDeletePayment = (state) => {
	return state.showDeletePayment
}
export const hideDeletePayment = (state) => {
	return state.hideDeletePayment
}
// ~~~~~~~

export const getAgreementList = (state) => {
	return state.agreementList
}

export const agreementIdDeleted = (state) => {
	return state.agreementIdDeleted
}

export const getUserRoles = (state) => {
	return state.userRoles
}

export const getAgreementIdEdit = (state) => {
	return state.agreementIdEdit
}


// check agreement
export const getCheckAgreementModel = (state) => {
      return state.showCheckAgreementModel;
};

// language
export const getLanguage = (state) => {
      return state.language;
}

export const getDataBeingEditForAutoEmail = (state) => {
	return state.editAutoEmail
}


export const getShowModalBoxSuccessfully = (state) => {
	return state.showModalBoxSuccessfully
}
export const hideModalBoxSuccessfully = (state) => {
	return state.hideModalBoxSuccessfully
}

export const getShowModalBoxNotSuccessfully = (state) => {
	return state.showModalBoxNotSuccessfully
}
export const hideModalBoxNotSuccessfully = (state) => {
	return state.hideModalBoxNotSuccessfully
}


///integration discount promotion
export const getDiscountListData = (state) => {
	return state.discountList.data
}
export const getDiscountPromoLinks = (state) => {
	return state.discountList.links
}
export const getDiscountPromoMeta = (state) => {
	return state.discountList.meta
}

export const getShowPromoPlanDelete = (state) => {
	return state.showPromoPlanDelete
}
export const hidePromoPlanDelete = (state) => {
	return state.hidePromoPlanDelete
}

export const getDiscountPlanId = (state) => {
      return state.discountId
};
export const getPromoPlanById = (state) => {
	return state.discountList.plan
}

////////////////////////////////////////////

export const getGeneral = (state) => {
      return state.general.data;
}


// slack function
export const getDeleteSlackFunction = (state) => {
	return state.showDeleteSlackFunction
}
///////

// slack integration
export const getShowAddNewSlack = (state) => {
	return state.showAddNewSlack
}
export const getShowDeleteSlackAndModule = (state) => {
	return state.showDeleteSlackAndModule
}
////////

