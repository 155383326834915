export const fetchUsers = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/users').then((response) => {
        commit('setUser', response.data)
    })
}

export const fetchAgreements = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/agreements').then((response) => {
        commit('setAgreement', response.data)
    })
}

export const fetchRefferals = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/refferals').then((response) => {
        commit('setRefferals', response.data)
    })
}

export const fetchEvent = ({ commit }) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/event-exhibitions').then((response) => {
        commit('setEvent', response.data)
    })
}