export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const getRoleList = (state) => {
	return state.roleList.data
}

export const getRoleListLinks = (state) => {
    return state.roleList.links
}

export const getRoleListMeta = (state) => {
    return state.roleList.meta
}

export const getRoleListCopy = (state) => {
	return state.roleListCopy
}

export const getPermissionCategoryList = (state) => {
	return state.permissionCategoryList
}

export const showDeleteRole = (state) => {
	return state.showDeleteRole
}

export const hideDeleteRole = (state) => {
	return state.hideDeleteRole
}

export const currentDeleteModalId = (state) => {
	return state.currentIdBeingDeleted
}

export const getDataBeingEditRole = (state) => {
    return state.editRole
}