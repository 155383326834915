export const setGalleryList = (state, data) => {
	return state.galleryList = data
}
export const showGalleryDeleteModal = (state, {flag, value}) => {
    state.showGalleryDeleteModal = flag
	state.presentGalleryIdDelete = value
	state.editIdGallery = value
}
export const hideGalleryDeleteModal = (state) => {
    state.showGalleryDeleteModal = false
}

export const setEditGalleryList = (state, data) => {
	state.editIdGallery.gallery_name = data.data.gallery_name
    state.editIdGallery.category = data.data.category
    state.editIdGallery.co_organizer = data.data.co_organizer
    state.editIdGallery.description = data.data.description
    state.editIdGallery.management = data.data.management
    state.editIdGallery.organizer = data.data.organizer
    state.editIdGallery.sub_category = data.data.sub_category
    state.editIdGallery.images = data.data.images
    console.log(state.editIdGallery.images)

}

export const showImageGalleryDeleteModal = (state, {flag, value}) => {
    state.showImageGalleryDeleteModal = true
    state.updateImageGalleryIdDelete = value
}
export const hideImageGalleryDeleteModal = (state) => {
    state.showImageGalleryDeleteModal = false
    state.updateImageGalleryIdDelete = null
}


