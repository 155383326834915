export default {
	user: {
		token: null,
		authenticated: false,
		data: null,
		roleName: null,
		roleSlug: null,
	},

	showTab: true,
	
}