<template>
    <div> 
        <div class="fixed w-full bg-white z-50 ">
            <div class="hidden">
                <button @click="showList = !showList">
                    <svg class="w-8 lg:w-12 fixed z-50 -mt-3 sm:mt-4 ml-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg> 
                </button>
            </div>
           
            <div class="flex justify-end space-x-1 mt-4 md:mt-0 lg:space-x-6 my-2 xl:my-2 md:px-10 absolute md:static right-0 ">
                <div class="bg-white flex shadow-md rounded-full p-0.5 border my-auto w-20 md:w-36">
                    <a :href="sekaiIchi" target="_blank" class="flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white w-full">
                        <img src="/images/sekai-logo.png" class="bg-white rounded-full px-1 h-7 w-full object-contain p-0.5">
                    </a>
                </div>     
                <div class="bg-white flex shadow-md rounded-full p-0.5 border my-auto w-20 md:w-36 relative">
                    <a href="#" class="group relative rounded-full px-3 border hover:border-gray-400 bg-white flex justify-center w-full">
                        <img src="/images/smartRecruitNew.jpg" class="bg-white object-cover max-h-8">
                        <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                            coming soon...
                            <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                        </div>
                    </a>
                </div>           
              
                <div class="bg-white flex shadow-md rounded-full p-0.5 border my-auto w-20 md:w-32">
                    <a :href="jiipaClientUrl" target="framename" class="cursor-pointer flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white w-full">
                        <img src="/images/jiipa.png" class="bg-white rounded-full px-1 h-7 w-full object-contain p-1">
                    </a>
                </div>

                <div class="relative inline-flex shadow-md rounded-full p-0.5 border">
                    <svg class="w-2 absolute top-0 right-0 m-4 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#000" fill-rule="nonzero"/></svg>
                    <select class="md:w-36 w-16 border border-gray-300 rounded-full pl-4 pr-8 font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none" @change="languageHandler()" v-model="locale">
                        <option value="" hidden>Language</option>
                        <option :value="item.slug" v-for="(item, i) in languages" :key="i">{{item.name}}</option>
                    </select>
                </div> 

                <div class="flex xl:hidden block space-x-3" v-if="user.authenticated">
                     <div class="my-auto">
                        <span>
                            <svg class="fill-current text-white p-1 bg-red-600 hover:bg-red-700 rounded-full" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/></svg>
                        </span>
                    </div>
                <div class="flex items-center">
                  <button @click="dropIt = !dropIt">
                        <img v-if="myProfile.profile_pic" :src="myProfile.profile_pic" class="w-8 h-8 border rounded-full object-cover" >
                        <img v-else src="/images/user.png" class="w-8 h-8 border border-gray-300 hover:border-gray-400 rounded-full object-cover" >
                  </button>
                  <!-- profile dropdown -->
                  <button v-if="dropIt" @click="dropIt = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default z-50"></button>
                  <div class="relative z-50" v-if="dropIt">
                    <ul class="absolute -right-2 top-9 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg" >
                        <div class="flex flex-col" >
                            <router-link :to="{name:'my-profile'}" class="hover:bg-gray-200 pl-4 py-2 rounded-t-md"> <!-- My Profie --> {{$t('CADL-h3')}}</router-link>
                            <router-link :to="{name:'dashboard'}" class="hover:bg-gray-200 pl-4 py-2"> <!-- Dashboard -->{{$t('dashboard')}} </router-link>
                            <router-link :to="{name:'change-password'}" class="hover:bg-gray-200 pl-4 py-2"> <!-- Change Password --> {{$t('EUCP-h1')}} </router-link>
                            <span class="px-3"><hr></span>
                            <router-link to="#" v-on:click="logout" class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-gray-500 hover:text-gray-800"><!-- Logout -->{{$t('esi-txt2')}} </router-link>
                        </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>        

            <header class="" id="header-sticky">
                <div class=" border-b relative h-16">
                    <img src="/images/header_background.png" class="w-full h-full hidden lg:block">
                    <div class="absolute inset-0 flex justify-between items-center px-10">
                      <div class="my-auto">
                        <router-link to="/" class="h-14 hidden lg:block">
                          <img src="/images/jiipa.png" class="w-14 xl:w-full h-12">
                        </router-link>
                      </div>
                      <div class="flex inline-flex items-center space-y-2">          
                        <div class="flex items-center space-x-6 xl:space-x-8 text-xs lg:text-sm">
                            <a :href="base_url_home" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('eh-nb-title1')}}
                            </a> 
                            <a :href="base_url_about" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('eh-nb-title2')}}
                            </a>
                            <a :href="base_url_services" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('CADL-h12')}}
                            </a>
                            <a :href="base_url_events" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('eh-nb-title4')}}
                            </a>
                            <a :href="base_url_contact" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('eh-nb-title5')}}
                            </a>
                            <a :href="base_url_newsletter" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('CADL-h14')}}
                            </a>
                            <a :href="base_url_gallery" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                            {{$t('eh-nb-title6')}}
                            </a>
                        
                        <div class="xl:block hidden">
                            <div class="md:flex space-x-3" v-if="user.authenticated">
                                <div class="my-auto">
                                    <span>
                                        <svg class="fill-current text-white p-1 bg-red-600 hover:bg-red-700 rounded-full" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z"/></svg>
                                    </span>
                                </div>
                                <div class="flex items-center">
                                <button @click="dropIt = !dropIt" class="">
                                    <img v-if="myProfile.profile_pic" :src="myProfile.profile_pic" class="w-8 h-8 border rounded-full object-cover" >
                                    <img v-else src="/images/user.png" class="w-8 h-8 border border-gray-300 hover:border-gray-400 rounded-full object-cover" >
                                </button>
                                <button v-if="dropIt" @click="dropIt = false" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-black opacity-0 cursor-default z-50"></button>
                                <!-- profile dropdown -->
                                <div class="relative z-50" v-if="dropIt">
                                    <ul class="absolute -right-2 top-9 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg">
                                        <div class="flex flex-col">
                                            <router-link :to="{name:'my-profile'}" class="hover:bg-gray-200 pl-4 py-2 rounded-t-md"> <!-- My Profie --> {{$t('CADL-h3')}}</router-link>
                                            <router-link :to="{name:'dashboard'}" class="hover:bg-gray-200 pl-4 py-2"> <!-- Dashboard -->{{$t('dashboard')}} </router-link>
                                            <router-link :to="{name:'change-password'}" class="hover:bg-gray-200 pl-4 py-2"> <!-- Change Password --> {{$t('EUCP-h1')}} </router-link>
                                            <span class="px-3"><hr></span>
                                            <router-link to="#" v-on:click="logout" class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-gray-500 hover:text-gray-800"><!-- Logout -->{{$t('esi-txt2')}} </router-link>
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

            </header>
        </div>
        <!-- ~~~~~~~~~~~~~~~~~~~~~~~responsive -->
        <header class="hidden md:block">
            <div class="container mx-auto px-4 py-2 flex justify-between bg-gradient-to-r from-blue-100 to-blue-50">
                 <div class="">
                    <router-link to="/">
                        <img src="/images/jiipa.png" class="">
                    </router-link>
                </div>      
            </div>
        </header>

  <!-- ~~~~~~~~~~~~~~~responsive header list -->
        <div class="container mx-auto bg-white">
            <transition name="slide">
                <section  v-if="showList" class="absolute right-0 inset-y-0 w-full h-full z-50 flex justify-end" > 
                    <div class="bg-white shadow-xl w-full border-l max-w-xl h-full fixed">
                        <div class="flex justify-between p-4">   
                            <div class="">
                                <div class="flex flex-col space-y-6">
                                    <a :href="base_url_home" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title1')}}
                                    </a> 
                                    <a :href="base_url_about" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title2')}}
                                    </a>
                                    <a :href="base_url_services" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('CADL-h12')}}
                                    </a>
                                    <a :href="base_url_events" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title4')}}
                                    </a>
                                   <!--  <a :href="base_url_membership" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title3')}}
                                    </a> -->
                                    <a :href="base_url_contact" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title5')}}
                                    </a>
                                    <a :href="base_url_newsletter" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('CADL-h14')}}
                                    </a>
                                    <a :href="base_url_gallery" target="framename" class="uppercase text-gray-800 font-bold hover:text-green-600">
                                    {{$t('eh-nb-title6')}}
                                    </a>
                                </div>
                            </div>           
                            <div>
                                <button @click="closeDeleteModel" class="inline-flex items-center transform hover:scale-125 hover:text-red-600 focus:outline-none"> 
                                   <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg>
                                </button>
                            </div>
                        </div>      
                    </div>
                </section>
            </transition>
        </div>
  <!-- ~~~~~~~~~~~~~~~~~~ -->
    </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

import { isEmpty } from 'lodash'

export default {  
  data: function() {
    return {
        locale: 'en',
        base_url_home:'',
        base_url_about:'',
        base_url_services:'',
        base_url_events:'',
        base_url_contact:'',
        base_url_newsletter:'',
        base_url_gallery:'',
        jiipaClientUrl: process.env.VUE_APP_JIIPA_URL,
        recruitClientUrl: process.env.VUE_APP_SMART_RECRUIT_URL,
        recruitAdminUrl: process.env.VUE_APP_SMART_RECRUIT_Admin_URL,
        sekaiIchi: process.env.VUE_APP_SEKAI_ICHI_URL+'product.php',
        dropIt: false,
        showList:false,
        myProfile: {
            name:"",
            user_role: "",
            last_name: "",
            designation:"",
            profile_pic:"",
        },
        languages:[],
        intervalId:''
    }
  },
  components: {

  },

  computed: {
    ...mapGetters({
        user: 'auth/user',
        getGeneral: 'settings/getGeneral',          
        getEditUserData: 'profile/getEditUserData',
      
    })
  },

  methods: {
    ...mapMutations({
      }),

    ...mapActions({
        fetchGeneral: 'settings/fetchGeneral', 
        languagesList:'languagesList'            
    }),

    fetchGeneralList(){             
        this.fetchGeneral().then((response) => {    
            this.base_url_home = this.getGeneral.base_url;
            this.base_url_about = this.getGeneral.base_url + 'about-us';
            this.base_url_services = this.getGeneral.base_url + 'services';
            this.base_url_events = this.getGeneral.base_url + 'events';
            this.base_url_contact = this.getGeneral.base_url + 'contact-us';
            this.base_url_newsletter = this.getGeneral.base_url + 'newsletter';
            this.base_url_gallery = this.getGeneral.base_url + 'gallery';
        })
    },


    languageHandler() {
        this.$store.commit('changeLanguageSlug', this.locale)
    },
    getWebLang(){
          this.setWebLang(this.locale);
      },
    closeDeleteModel(){
      this.showList = false
    },
 
    logout() {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.replace({ name: 'Sign-In' })
      })
    },
    getLanguages(){
        try{
            clearInterval(this.intervalId);
            this.languagesList().then((res) => {
                this.languages = res.data;
            });
        }catch(error){
            console.log('e',error);
        }
    }
  }, 

  mounted() {
    this.getLanguages();
    this.fetchGeneralList();
    this.myProfile = this.getEditUserData;
    let currentLocale = 'jp'
    if (!isEmpty(localStorage.getItem('localforage/jiipa_admin/locale'))) {
        currentLocale = localStorage.getItem('localforage/jiipa_admin/locale').replace(/['"]+/g, '')
    }
    this.locale = currentLocale || 'jp';

    let vm = this
    this.$store.commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH')
   
    this.$store.commit('isAuthenticated', false)
  },
};
</script>

<style>
/*.slide-enter-active {
  transition: 0.6s;
}*/
/*.slide-leave-active{
  transition: 0.6s;
}*/
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.menu_active.router-link-exact-active{
    color: #059669;
    text-decoration: underline;
}

</style>
