 //--------------------------- add Gallery ----------------------
export const addGallery = ({ commit }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_JIIPA_API_URL +'api/my-profile-gallery', payload,{
        config:  { headers: { "Content-Type": "multipart/form-data"} },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        window.location = "/gallery"
        return response.data.success
    }).catch((error) => {
        // Showing  Error message
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


// ------------------------ listing gallery -------------------------- 

export const fetchGalleryList = ({ commit }) => {
    return window.axios.get (process.env.VUE_APP_JIIPA_API_URL + 'api/my-profile-gallery').then((response) => {
        commit('setGalleryList', response.data.data);

    })
}
// ---------------------- Delete Gallery ------------------
export const deleteGallery = ({ commit, state, dispatch }, id) => {
    id = state.presentGalleryIdDelete
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/my-profile-gallery/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchGalleryList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// ---------------------- Edit Gallery ---------------
export const editGalleryList = ({ commit, state }, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/my-profile-gallery/${id}`).then((response) => {
        commit('setEditGalleryList', response.data);
    })
}

// -------------------- update gallery --------------

export const updateGallery = ({ commit },{ id, payload, context } ) => {
    console.log(id,payload)
  return window.axios({
    method: 'post',
    url:process.env.VUE_APP_JIIPA_API_URL + `api/my-profile-gallery/${id}`,
    data: payload,
    config: { headers: { "Content-Type": "multipart/form-data" } },
  }).then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      window.location = "/gallery";
      return response.data.sucess;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// -----------------------==== Delete Images through Update gallery ====---------------------
export const DeleteImageGallery = ({ commit, state }, id) => {
    id = id ? id : payloadstate.updateImageGalleryIdDelete
    return window.axios.delete (process.env.VUE_APP_JIIPA_API_URL + `api/gallery-images-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
       window.location.reload();
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

//-------------list category-------------------------------------

export const fetchCategoryList = ({ commit }, query = '') => {
    var  url ='';
    if(query && query.searchQuery && (query.searchQuery != 'undefined')){
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/gallery-category-list?name='+ query.searchQuery;
    }else{
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/gallery-category-list';
    }
    return window.axios.get(url).then((response) => {
        return response.data;
        
    })
}

