export default [
	{
		path: '/localization',
		component: () => import('../components/Localization.vue'),
		name: 'localization',
		meta: {	
			guest: false,
			needsAuth: true		
		}
	},
]
