// put your main mutations here

import localforage from 'localforage'
import { isEmpty } from 'lodash'

const IS_LOADING = (state, flag) => {
	state.isLoading = flag
}

const UNSET_ALERT = (state, alertName) => {
	state.alerts[alertName] = false
}

const SET_ALERT = (state, alertName) => {
	// Reset all alerts
	Object.entries(state.alerts).forEach((key, value) => {
		state.alerts[key] = false
	})
	state.alerts[alertName] = true

	// Fadeout the alert in configured time
	setTimeout(function() {
		state.alerts[alertName] = false
    }, window.alertDuration);
}

const SET_ALERT_MESSAGE = (state, message) => {
	state.alerts.message = message
}
const SET_CITY_DATA = (state, data) => {
    state.cities = data
}

const SET_COUNTRY_DATA = (state, data) => {
    state.countries = data
}


const HANDLE_MAIN_HEADER_BASED_ON_AUTH = (state) => {


	localforage.getItem('authtoken').then((response) => {
		// if (isEmpty(response)) {
		// 	state.headers.mainHeaderLinks[8].show = false
		// 	state.headers.mainHeaderLinks[9].show = true
		// 	// state.headers.mainHeaderLinks[10].show = false
		// 	// state.headers.mainHeaderLinks[11].show = false

		// } else {
		// 	state.headers.mainHeaderLinks[8].show = true
		// 	state.headers.mainHeaderLinks[9].show = false
		// 	// state.headers.mainHeaderLinks[10].show = true
		// 	// state.headers.mainHeaderLinks[11].show = true

		// }
	})


}
export default {
	IS_LOADING,
	UNSET_ALERT,
	SET_ALERT,
	SET_ALERT_MESSAGE,
	HANDLE_MAIN_HEADER_BASED_ON_AUTH,
	SET_CITY_DATA,
	SET_COUNTRY_DATA,
	isLoading(state, flag) {
		state.isLoading = flag
	},
	isAuthenticated(state, flag) {
		state.isAuth = flag
	},
	setShowDeleteMembership(state, value) {
  		this.state.setShowDeleteMembership = value
  	},
  	changeLanguageSlug(state, locale) {
  		console.log("Here");
		state.locale = locale
		localforage.setItem('locale', locale);
		window.location = window.location.href
 	},
}

	// setShowDeleteMembership(state, value) {
 //  		this.state.setShowDeleteMembership = value
 //  	},


