export default [
	{
		path: '/agreements',
		component: () => import('../components/AgreementsView.vue'),
		name: 'agreements',
		meta: {	
			guest: false,
			needsAuth: true		
		}
	},
	{
		path: '/edit-agreements',
		component: () => import('../components/AgreementsEdit.vue'),
		name: 'edit-agreements',
		meta: {	
			guest: false,
			needsAuth: true		
		}
	},
]
