export default {
  state: {
    showViewErrorLog: false,
    hideViewErrorLog: false,

    // showDeleteErrorLog: false,
    // hideDeleteErrorLog: false,
    
  },
  getters: {
    showViewErrorLog(state) {
      return state.showViewErrorLog
    },
    hideViewErrorLog(state) {
      return state.hideViewErrorLog
    },
    // showDeleteErrorLog(state) {
    //   return state.showDeleteErrorLog
    // },
    // hideDeleteErrorLog(state) {
    //   return state.hideDeleteErrorLog
    // }
     
  },
  mutations: {
    showViewErrorLog(state, flag) {
      state.showViewErrorLog = flag
    },
    hideViewErrorLog(state) {
      state.showViewErrorLog = false
    },
    // showDeleteErrorLog(state, flag) {
    //   state.showDeleteErrorLog = flag
    // },
    // hideDeleteErrorLog(state) {
    //   state.showDeleteErrorLog = false
    // }
    
  }
}