export default [
	{
		path : '/newsletter',
		component: () => import('../components/Newsletter.vue'),
		name : 'newsletter',
		meta: {
			guest: false,
			needsAuth: true
		}
	},
]	