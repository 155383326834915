export default {
	// editPlan: {
	// 	name: '',
	// 	cost: '',
	// 	status : '',
	// 	scope_id: [],
	// 	about_membership : '',
	// 	title: '',
	// 	message:'',
	// 	s_token: process.env.VUE_APP_SSO_PUBLIC_KEY,
	// 	c_token: process.env.VUE_APP_JIPPA_PUBLIC_KEY
	// },
	showAddMembershipPlan: false,
	hideAddMembershipPlan: false,

	showEditMembershipPlan: false,
	hideEditMembershipPlan: false,

	showDeleteMembershipPlan: false,
   	hideDeleteMembershipPlan: false,

   	showViewMembershipPlan: false,
   	hideViewMembershipPlan: false,
	// showEditMembershipPlan: false,
	// currentEditId: null,
	showAddMembershipScope: false,
	hideAddMembershipScope: false,

	showDeleteMembershipScope: false,
	hideDeleteMembershipScope: false,

	showEditMembershipScope: false,
	hideEditMembershipScope: false,

	PlanList: {
		data: null,
		links: null,
		meta: null,
	},
	scopeList: {
		pageData: [],
		pagelinks: [],
		data: null,
		links: null,
		meta: null,
	},
   	membershipPlanIdEdit: null,   
	
}