export default{
  
  state: {
    showDeleteContract: false,
    hideDeleteContract: false,
  },
   
  getters: {
    showDeleteContract(state) {
      return state.showDeleteContract
    },
    hideDeleteContract(state) {
      return state.hideDeleteContract
    }
  },
  mutations: {
    showDeleteContract(state, flag) {
      state.showDeleteContract = flag
    },
    hideDeleteContract(state) {
      state.showDeleteContract = false
    }
  },
}