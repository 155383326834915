export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const getPhoneCode = (state) => {
	return state.phoneCode
}

export const getCityData = (state) => {
	return state.cities
}

// Integration with pagination, seraching and sorting
export const getUserListData = (state) => {
	console.log('checkuser', state.listUsers.data)
	return state.listUsers.data
}
export const getUserListLinks = (state) => {
	return state.listUsers.links
}
export const getUserListMeta = (state) => {
	return state.listUsers.meta
}

export const getMyRoleList = (state) => {
	return state.myRoles
}