import { isEmpty } from 'lodash';

// Add New Agreement
export const addNewAgreement = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/contract-agreement', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

//  list Agreements
export const fetchAgreementList = ({commit, dispatch}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/contract-agreement').then((response) => {          
        commit('setAgreementList', response.data.data);  
    })
}
// ~~~~~~~~~

// delete agreement
export const deleteNewAgreement = ({ commit, state, dispatch }, id) => {
    id = state.agreementIdDeleted
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/contract-agreement/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchAgreementList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// ~~~~EditAgreement
export const updateAgreement = ({ commit },{ id, payload, context } ) => {
    // id = state.agreementIdEdit
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + `api/contract-agreement/${id}`, payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // window.location = "settings";
      return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// ~~~~~
// get by ID agreement
export const fetchAgreementByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/contract-agreement/${id}`).then((response) => {   
        console.log(response.data,response);
        return response.data
    })
}
// 

// role dropdown
export const userRoles = ({ commit }, id) => {
  return window.axios
    .get(process.env.VUE_APP_SSO_API_URL + 'api/get/role/JIIPA')
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setUserRole", response.data.roles);
      return response.data.roles;
    })
    // .catch((error) => {
    //   commit("SET_ALERT", "warning", { root: true });
    //   commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    //   return false;
    // });
};
// 


// language get
export const agg_language = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/language-mapping")
    .then((response) => {
      // commit("SET_ALERT", "success", { root: true });
      // commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit("setLanguage", response.data.data);
      return response.data.data;
    })
    // .catch((error) => {
    //   commit("SET_ALERT", "warning", { root: true });
    //   commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
    //   return false;
    // });
};
// 


// AUTO EMAIL DISPATACH
export const newAutoEmail = ({ commit }, { payload, context }) => {
  
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + "api/auto-email/store", payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchAutoEmailById = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + "api/auto-email/get").then((response) => {           
        commit('fetchEditAutoEmailId', response.data);
        return response.data

    })
}


// discount promotion integration
export const fetchDiscountPromoList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/promotion-plan-list' + query).then((response) => {
        commit('setDiscountList', response.data)           
    })
}

// delete promotion plan
export const deletePromoPlan = ({ commit, state, dispatch }, id) => {
    id = state.promoPlanDeleted
    console.log(id);
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/promotion-plan-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "settings"
        dispatch("fetchDiscountPromoList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// / Add discount plan
export const addDiscountProPlan = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/promotion-plan', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
  // return window.axios.post (process.env.VUE_APP_JIIPA_API_URL + 'api/promotion-plan', payload).then((response) => {
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // window.location = "settings";
      return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


export const fetchDiscountPlanByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/promotion-plan-edit/${id}`).then((response) => {   
        commit('setPromoPlanById', response.data);        
        return response.data
    })
}


// ~~~~updateDiscountPlan
export const updateDiscountPlan = ({ commit },{ id, payload, context } ) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + `api/promotion-plan-update/${id}`, payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
      commit("SET_ALERT", "success", { root: true });
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // window.location = "settings";
      return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// ~~~~~
////////////////////////////////////////////

export const postGeneral = ({ commit }, { payload, context}) => {
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/setting-general', payload).then((response) => {
      commit('SET_ALERT', 'success', { root: true })
      commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      return response.data.success
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
}

export const fetchGeneral = ({ commit}) => {
  return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/setting-general').then((response) => {
      commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      commit('setGeneral', response.data)
      return response.data;
  }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
      return false;
  });   
};

//------------------------File Server--------------------------

export const storeFileServer = ({ commit}, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/file-server',payload).then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
            return response.data;
        }
        return false;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        context.isDisable = false;
        context.btnName = context.btn;
        return false;
    });   
  };

  export const getFileServer = ({ commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/get-file-server').then((response) => {
        if(response.data.success){
            return response.data;
        }
        return false;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong", { root: true });
        return false;
    });   
  };

  export const testConnection = ({ commit}, {context}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/test-connection').then((response) => {
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
            return true;
        }
        return false;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE",  error.response.data.message, { root: true });
        context.testBtn = context.btn2;
        context.isDisable = false;
        context.disebelField = false;
        return false;
    });   
  };
