// export const showViewFeedback = (state, {flag, value}) => {
// 	state.showViewFeedback = flag
// 	state.feedbackById = value	
// }

export const showDeleteFeedback = (state, { flag, value }) => {
	state.showDeleteFeedback = flag
	state.feedbackIdDeleted = value	
}

export const setFeedbackList = (state, data) => {
	return state.feedbackList = data
}

// publish slide
export const setFeedbackPublishList = (state, data) => {
	return state.feedbackPublishList = data
}
////


