export default {
  state: {
    showAddPaymentModal: false,
    hideAddPaymentModal: false,
    editPaymentModal: false,
    updatePaymentModal: false,
    showRemovePaymentModal: false,
    hideRemovePaymentModal: false,
  },
  getters: {
    showAddPaymentModal(state) {
      return state.showAddPaymentModal
    },
    hideAddPaymentModal(state) {
      return state.hideAddPaymentModal
    },
     editPaymentModal(state) {
      return state.editPaymentModal
    },
     updatePaymentModal(state) {
      return state.updatePaymentModal
    },
    showRemovePaymentModal(state) {
      return state.showRemovePaymentModal
    },
    hideRemovePaymentModal(state) {
      return state.hideRemovePaymentModal
    }
  },
  mutations: {
    showAddPaymentModal(state, flag) {
      state.showAddPaymentModal = flag
    },
    hideAddPaymentModal(state) {
      state.showAddPaymentModal = false
    },
    editPaymentModal(state, flag) {
      state.editPaymentModal = flag
    },
    updatePaymentModal(state) {
      state.editPaymentModal = false
    },
    showRemovePaymentModal(state, flag) {
      state.showRemovePaymentModal = flag
    },
    hideRemovePaymentModal(state) {
      state.showRemovePaymentModal = false
    }
  }
}