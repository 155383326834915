<template>
	<div class="">
		<div class="w-full">
			<div class="border border-white w-full bg-white h-20 top-0 z-30 bg-white" id="title_fixed">
	            <div class="relative border-b-2 border-gray-400 mt-12 xl:mt-8 mb-10 text-xs md:text-sm">
	               <div class="absolute inset-x-8 -top-5">
	                   <div class="flex justify-between">
	                      <label class="text-center shadow-customInner w-32 lg:w-64 rounded-full h-10 flex justify-center items-center text-black font-semibold bg-white"><!-- Feedback/Reviews -->{{$t('side-mod-head-2-con-9')}}</label>
	                        <div class="flex gap-4 item-center">
	                         
	                     </div>  
	                   </div> 
	               </div>
	            </div>
	         </div>

			<div class="md:px-6">
				<div class="bg-gray-700 lg:flex justify-between p-3 space-y-2 lg:space-y-0 rounded-md gap-6 xl:gap-10 mb-4">
				<div class="w-full lg:w-4/12">
					<div class="relative flex gap-2">
						<div class="inline-flex items-center my-auto absolute top-0.5 w-10 text-gray-400">
							<span class="my-auto">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2 mt-1 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
							</span>
						</div>
						<input type="search" v-model="searchQuery" class="text-sm placeholder-gray-500 pl-8 pr-4 py-1 border border-gray-400 w-full focus:outline-none focus:border-blue-400 rounded-full  my-auto" :placeholder="$t('CALMP1-h8')" />
						<button class="relative my-auto flex text-sm text-black bg-white gap-2 border border-white px-2 py-0.5 rounded-md">
							<span v-if="!sorting" @click="handleSorting" class="my-auto cursor-pointer">
								<svg class="fill-current text-black hover:text-gray-600" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.168 11.75L11.0517 10.8663L13.293 13.1075V0.5H14.543V13.1075L16.7842 10.8663L17.668 11.75L13.918 15.5L10.168 11.75Z" fill=""/><path d="M0.171875 9.25391H8.92188V10.5039H0.171875V9.25391Z" fill=""/><path d="M2.66797 5.50391H8.91797V6.75391H2.66797V5.50391Z" fill=""/><path d="M5.17578 1.75391H8.92578V3.00391H5.17578V1.75391Z" fill=""/></svg>
							</span>

							<span v-if="sorting"  @click="handleSorting" class="my-auto cursor-pointer">
								<svg class="fill-current text-black hover:text-gray-600" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99609 3.75L10.8798 4.63375L13.1211 2.3925V15H14.3711V2.3925L16.6123 4.63375L17.4961 3.75L13.7461 0L9.99609 3.75Z" fill=""/><path d="M0 6.24609H8.75V4.99609H0V6.24609Z" fill=""/><path d="M2.49609 9.99609H8.74609V8.74609H2.49609V9.99609Z" fill=""/><path d="M5.00391 13.7461H8.75391V12.4961H5.00391V13.7461Z" fill=""/></svg>                                            
							</span>
							<!-- Sort -->{{$t('SACU01-h2')}}
						</button>
					</div>      
				</div>
				<!-- <div class="w-full lg:w-4/12">
					<div class="relative flex gap-2">
						<input type="text" class="text-sm placeholder-gray-500 pl-8 pr-4 py-1 border border-gray-400 w-full focus:outline-none focus:border-blue-400 rounded-full  my-auto" :placeholder="$t('side-mod-head-2-con-9')" />						
					</div>      
				</div> -->
				<div class="w-full lg:w-4/12">
					<div class="relative flex gap-2">
						<div class="inline-flex items-center my-auto absolute top-0.5 w-10 text-gray-400">
							<span class="my-auto">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-2 mt-1 text-gray-400 hover:text-blue-400 transition duration-100 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
							</span>
						</div>
						<input type="date" class="text-sm placeholder-gray-500 pl-8 pr-4 py-1 border border-gray-400 w-full focus:outline-none focus:border-blue-400 rounded-full  my-auto" :placeholder="$t('SAEL-h7')" />
						<button class="relative my-auto flex text-sm text-black bg-white gap-2 border border-white px-2 py-0.5 rounded-md">
							<span v-if="!sorting" @click="handleSorting"  class="my-auto cursor-pointer">
								<svg class="fill-current text-black hover:text-gray-600" width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.168 11.75L11.0517 10.8663L13.293 13.1075V0.5H14.543V13.1075L16.7842 10.8663L17.668 11.75L13.918 15.5L10.168 11.75Z" fill=""/><path d="M0.171875 9.25391H8.92188V10.5039H0.171875V9.25391Z" fill=""/><path d="M2.66797 5.50391H8.91797V6.75391H2.66797V5.50391Z" fill=""/><path d="M5.17578 1.75391H8.92578V3.00391H5.17578V1.75391Z" fill=""/></svg>
							</span>

							<span v-if="sorting"  @click="handleSorting" class="my-auto cursor-pointer">
								<svg class="fill-current text-black hover:text-gray-600" width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99609 3.75L10.8798 4.63375L13.1211 2.3925V15H14.3711V2.3925L16.6123 4.63375L17.4961 3.75L13.7461 0L9.99609 3.75Z" fill=""/><path d="M0 6.24609H8.75V4.99609H0V6.24609Z" fill=""/><path d="M2.49609 9.99609H8.74609V8.74609H2.49609V9.99609Z" fill=""/>
									<path d="M5.00391 13.7461H8.75391V12.4961H5.00391V13.7461Z" fill=""/></svg>
							</span>
							<!-- Sort -->{{$t('SACU01-h2')}}
						</button>
					</div>      
				</div>
			</div>

			<div class="space-y-5">
				<div v-for="(feedbackList, k) in filteredFeedbackList" :key="k">
					<div class="flex px-2 lg:px-4 py-4 space-x-3 md:space-x-6 bg-green-50 rounded-md h-48">
						<div class="w-4/12 lg:flex items-center space-y-2 xl:space-y-0 lg:space-x-5">
							<div class="w-24 lg:w-4/12 mx-auto">
								<img src="images/profile02.jpg" class="rounded-full border object-cover h-20 lg:h-12 w-20 xl:h-20 xl:w-20">
							</div>
							<div class="w-full lg:w-8/12 space-y-1 lg:space-y-5">
								<div class="flex space-x-2 text-small sm:text-xs xl:text-sm">
									<label class="font-semibold"><!-- Name --> {{$t('CALMP1-h8')}}:</label>
									<label class="">
										{{ feedbackList.first_name }}
										{{ feedbackList.last_name }}									
									</label>
								</div>
								<div class="flex space-x-2 text-small sm:text-xs xl:text-sm">
									<label class="font-semibold"><!-- Email -->{{$t('CALMP1-h10')}}:</label>
									<label class="">{{ feedbackList.email }}</label>
								</div>
								<div class="flex" >
									<label v-for="n in parseInt(feedbackList.star)">
										<svg class="fill-current text-yellow-400" xmlns="http://www.w3.org/2000/
										svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path 
										d="M0 0h24v24H0V0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 
										9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"/></svg>
									</label>									
								</div>
							</div>
						</div>
						<div class="w-6/12 p-2 cursor-pointer" >
							<!-- <p class="text-xs xl:text-sm h-36 overflow-hidden " @click="showViewFeedback(({flag:true, value:feedbackList.id}))">
							{{ feedbackList && feedbackList.message }} </p> -->
							<p class="text-xs xl:text-sm h-36 overflow-hidden ">
							{{ feedbackList && feedbackList.message }} </p>
						</div>
						<div class="w-2/12 relative">
							<div class="text-verySmall sm:text-xs lg:text-sm">
								<div class="font-semibold flex justify-end ">{{ feedbackList.date }}</div>
								<div class="flex justify-end ">{{ feedbackList.time }}</div>
							</div>
							<div class="lg:flex justify-end space-y-2 lg:space-y-0 lg:space-x-2 xl:space-x-4 absolute inset-x-0 bottom-0">
								<button type="button" :disabled="feedbackList.isPublish" @click="publishUnpublish({k:k, value:feedbackList.id})" class="xl:w-28 flex justify-center items-center text-small sm:text-xs xl:text-sm font-semibold py-1 bg-green-100 hover:bg-green-200 shadow-md rounded-md border border-green-300 " :class="[feedbackList.isPublish ? 'cursor-not-allowed' : '']">
									<svg v-if="feedbackList.isPublish" class="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
									<span v-if="!feedbackList.isPublish" ><!-- Publish -->{{$t('SASG-h10')}}</span>
								</button>
								<button @click="showDeleteFeedback({flag:true, value:feedbackList.id})" class="xl:w-24 text-small sm:text-xs xl:text-sm text-white font-semibold px-2 xl:px-4 py-1 bg-red-700 hover:bg-red-800 shadow-md rounded-md"><!-- Delete -->{{$t('CALER-h25')}}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			</div>
		</div>

		
		<!-- <div v-if="getViewFeedback">
			<ViewFeedback/>
		</div> -->
		<div v-if="getDeleteFeedback">
			<DeleteFeedback/>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

// import ViewFeedback from './partials/ViewFeedback.vue'
import DeleteFeedback from './partials/DeleteFeedback.vue'

export default  ({
	data: function() {
		return {
			searchQuery: '',
			isPublish: false,    	
			sorting: false,    	
			// photo:'',
			first_name:'',
			last_name:'',
			email:'',
			message:'',
			time:'',
			date:'',			
			star:'',
			feedbackPublishID: '',
			
		}
	},
	components: {
		// ViewFeedback,
		DeleteFeedback
 },
 computed: {
 	...mapGetters({
 		// getViewFeedback: 'feedbackReviews/getViewFeedback',
 		getDeleteFeedback: 'feedbackReviews/getDeleteFeedback',
 		getFeedbackList: 'feedbackReviews/getFeedbackList',
 		getFeedbackPublishById: 'feedbackReviews/getFeedbackPublishById',
 	}),
 	getFeedbackList() {
      return this.$store.getters['feedbackReviews/getFeedbackList'];
    },
    filteredFeedbackList() {
      return this.getFeedbackList.filter((feedback) => {
        const name = `${feedback.first_name} ${feedback.last_name}`.toLowerCase();
        return name.includes(this.searchQuery.toLowerCase());
      });
    },

 },
 methods: {
 	...mapMutations({
 		// 'showViewFeedback':'feedbackReviews/showViewFeedback',
 		'showDeleteFeedback':'feedbackReviews/showDeleteFeedback',
 	}),
 	...mapActions({
 		fetchFeedbackList: 'feedbackReviews/fetchFeedbackList',
 		feedbackPublishByID: 'feedbackReviews/feedbackPublishByID',
 	}),
 	getFeedback() {
 		this.fetchFeedbackList()
 	},
 	publishById(){
 		this.feedbackPublishByID()
 	},
 	handleSorting() {
 		this.sorting = !this.sorting;
 	},

 	publishUnpublish(event) {
 		const feedbackList = this.getFeedbackList[event.k];
      	feedbackList.isPublish = true;
 		this.feedbackPublishID = event.value
 		this.feedbackPublishByID(this.feedbackPublishID).then((success)=>{
 			if(success){
 				this.fetchFeedbackList();
            	this.isPublish = false
 			}else {
            	this.isPublish = false
        	}
 		})  		
 	},


    
 },
 mounted() {
 	this.getFeedback();   
 	this.publishById();   
 	this.$store.commit('isAuthenticated', true)
 }

});
</script>


