import { isEmpty } from 'lodash';
// list of feedback
export const fetchFeedbackList = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/feedback-review').then((response) => {          
        commit('setFeedbackList', response.data.data);   
        return response.data.data 
    })
}
// ~~~~~~~~~

// get by ID feedback
export const fetchFeedbackByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/feedback-review/${id}`).then((response) => {          
        return response.data.data
    })
}
// 

// delete feedback
export const deleteFeedback = ({ commit, state, dispatch }, id) => {
    id = state.feedbackIdDeleted
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/feedback-review/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "feedback-reviews"
        dispatch("fetchFeedbackList")
        return response.data.success
    })
}


 export const feedbackPublishByID = ({ commit }, id ) => {
  return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + `api/feedback-review-update/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success 
    })
}

// list of publish feedback
export const fetchFeedbackPublishList = ({commit}) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/feedback-publish-list').then((response) => {          
        commit('setFeedbackPublishList', response.data.data);   
        return response.data.data 
    })
}
// ~~~~~~~~~