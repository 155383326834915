export default {
  state: {
    // showViewMembershipPlan: false,
    // hideViewMembershipPlan: false,

    // showDeleteMembershipPlan:false,
   	// hideDeleteMembershipPlan :false,

   	// showAddMembershipPlan:false,

   	// hideEditMembershipPlan :false,

    // showAddMembershipScope:false,
    // hideAddMembershipScope :false,

    // showEditMembershipScope:false,
    // hideEditMembershipScope :false,

    // showDeleteMembershipScope:false,
    // hideDeleteMembershipScope :false,
    
    showDeleteNewsletter:false,
    hideDeleteNewsletter:false,

   
    showAddNewsletter:false,
    hideAddNewsletter:false,

    showEditNewsletter:false,
    hideEditNewsletter:false,

    // showDeleteEvent:false,
    // hideDeleteEvent:false,

    // showEditEvent:false,
    // hideEditEvent:false,

},
   
  getters: {
    // showViewMembershipPlan(state) {
    //   return state.showViewMembershipPlan
    // },
    // hideViewMembershipPlan(state) {
    //   return state.hideViewMembershipPlan
    // },

    // showDeleteMembershipPlan(state) {
    //   return state.showDeleteMembershipPlan
    // },
    // hideDeleteMembershipPlan(state) {
    //   return state.hideDeleteMembershipPlan
    // },

    // showAddMembershipPlan(state) {
    //   return state.showAddMembershipPlan
    // },
    // hideAddMembershipPlan(state) {
    //   return state.hideAddMembershipPlan
    // },
    // hideEditMembershipPlan(state) {
    //   return state.hideEditMembershipPlan
    // },

    //  showAddMembershipScope(state) {
    //   return state.showAddMembershipScope
    // },
    // hideAddMembershipScope(state) {
    //   return state.hideAddMembershipScope
    // },

    //  showEditMembershipScope(state) {
    //   return state.showEditMembershipScope
    // },
    // hideEditMembershipScope(state) {
    //   return state.hideEditMembershipScope
    // },

    // showDeleteMembershipScope(state) {
    //   return state.showDeleteMembershipScope
    // },
    // hideDeleteMembershipScope(state) {
    //   return state.hideDeleteMembershipScope
    // },

    showDeleteNewsletter(state) {
      return state.showDeleteNewsletter
    },
    hideDeleteNewsletter(state) {
      return state.hideDeleteNewsletter
    },

    // showAddEvent(state) {
    //   return state.showAddEvent
    // },
    // hideAddEvent(state) {
    //   return state.hideAddEvent
    // },

    showAddNewsletter(state) {
      return state.showAddNewsletter
    },
    hideAddNewsletter(state) {
      return state.hideAddNewsletter
    },

    showEditNewsletter(state) {
      return state.showEditNewsletter
    },
    hideEditNewsletter(state) {
      return state.hideEditNewsletter
    },

    // showDeleteEvent(state) {
    //   return state.showDeleteEvent
    // },
    // hideDeleteEvent(state) {
    //   return state.hideDeleteEvent
    // },

    // showEditEvent(state) {
    //   return state.showEditEvent
    // },
    // hideEditEvent(state) {
    //   return state.hideEditEvent
    // }
  },
  mutations: {
    // showViewMembershipPlan(state, flag) {
    //   state.showViewMembershipPlan = flag
    // },
    // hideViewMembershipPlan(state) {
    //   state.showViewMembershipPlan = false
    // },

    // showDeleteMembershipPlan(state, flag) {
    //   state.showDeleteMembershipPlan = flag
    // },
    //  hideDeleteMembershipPlan(state) {
    //   state.showDeleteMembershipPlan = false
    // },

    // showAddMembershipPlan(state, flag) {
    //   state.showAddMembershipPlan = flag
    // },
    //  hideEditMembershipPlan(state) {
    //   state.showEditMembershipPlan = false
    // },

    //  showAddMembershipScope(state, flag) {
    //   state.showAddMembershipScope = flag
    // },
    //  hideAddMembershipScope(state) {
    //   state.showAddMembershipScope = false
    // },

    //  showEditMembershipScope(state, flag) {
    //   state.showEditMembershipScope = flag
    // },
    //  hideEditMembershipScope(state) {
    //   state.showEditMembershipScope = false
    // },

    //  showDeleteMembershipScope(state, flag) {
    //   state.showDeleteMembershipScope = flag
    // },
    //  hideDeleteMembershipScope(state) {
    //   state.showDeleteMembershipScope = false
    // },

    showDeleteNewsletter(state, flag) {
      state.showDeleteNewsletter = flag
    },
     hideDeleteNewsletter(state) {
      state.showDeleteNewsletter = false
    },

    // showAddEvent(state, flag) {
    //   state.showAddEvent = flag
    // },
    //  hideAddEvent(state) {
    //   state.showAddEvent = false
    // },

     showAddNewsletter(state, flag) {
      state.showAddNewsletter = flag
    },
     hideAddNewsletter(state) {
      state.showAddNewsletter = false
    },

    showEditNewsletter(state, flag) {
      state.showEditNewsletter = flag
    },
     hideEditNewsletter(state) {
      state.showEditNewsletter = false
    },

    // showDeleteEvent(state, flag) {
    //   state.showDeleteEvent = flag
    // },
    //  hideDeleteEvent(state) {
    //   state.showDeleteEvent = false
    // },
    
    //   showEditEvent(state, flag) {
    //   state.showEditEvent = flag
    // },
    //  hideEditEvent(state) {
    //   state.showEditEvent = false
    // }
  },
}