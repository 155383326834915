export default [
	{
		path : '/events',
		component: () => import('../components/Event.vue'),
		name : 'events',
		meta: {

			guest: false,
			needsAuth: true

			
		}
	},
]