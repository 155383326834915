export default [
{
	path: '/membership-plans',
	component: () => import('../components/MembershipPlan.vue'),
	name: 'membership-plans',
	meta: {
		guest: false,
		needsAuth: true
	}
},

]
