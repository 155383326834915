import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const setRoleSlug = (state, slug) => {
    state.user.roleSlug = slug
    localforage.setItem('userrole', slug)
    state.showTab = true
    if (slug == 'job-seeker') {
        state.showTab = false
    }
}

export const setToken = (state, token) => {
    if (isEmpty(token)) {
        localforage.removeItem('authtoken', token)
        return
    }

    localforage.setItem('authtoken', token)
}

export const setAuthenticated = (state, flag) => {
    state.user.authenticated = flag
}

export const setUserData = (state, data) => {
    state.user.data = data
}

export const CHECK_ROLE_SELECTION = (state, context) => {
    if (isEmpty(state.user.roleSlug)) {
        context.$router.replace({ name: 'select-role' })
    }
}

export const setRolesList = (state, data) => {
    state.roleList.data = data.data
    state.roleList.links = data.links
    state.roleList.meta = data.meta
}

export const setRolesListCopy = (state, data) => {
    return state.roleListCopy = data
}

export const setPermissionCategoryList = (state, data) => {
    return state.permissionCategoryList = data
}

export const setEditRole = (state, id) => {
    return state.roleList = id
}

export const showDeleteRole = (state, {flag, value}) => {
    state.showDeleteRole = flag
    state.currentIdBeingDeleted = value
}

export const hideDeleteRole = (state) => {
    state.showDeleteRole = false
    state.currentIdBeingDeleted = null
}

export const setRolesEditData = (state, data) => {
    state.editRole.name = data.roles.name
    state.editRole.show_name = data.roles.show_name
    state.editRole.permissions = data.roles.permissions.map(function(value) {
        return value.name;
    });
}


