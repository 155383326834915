export const setNewsLetterList = (state,  flag) => {
	state.newsLetterList = flag
}
export const showDeleteNewsletter = (state, {flag , value}) => {
	state.showDeleteNewsletter = flag
	state.DeleteList = value
}
export const hideDeleteNewsletter = (state) => {
	state.showDeleteNewsletter = false
	state.DeleteList = false
}
export const showAddNewsletter = (state,  flag) => {
	state.showAddNewsletter = flag
}
export const hideAddNewsletter = (state) => {
	state.showAddNewsletter = false
}
export const showEditNewsletter = (state, {flag, value}) => {
	console.log(flag, value);
	state.showEditNewsletter = flag
	state.editNewsletterId = value
}
export const hideEditNewsletter = (state) => {
	state.showEditNewsletter = false
}
export const setNewsletterPdfDownload = (state) => {
	state.NewsLetterPdfs = false
}
export const showImageNewsletterDeleteModal = (state, {flag, value}) => {
    state.showImageNewsletterDeleteModal = flag
    state.updateImageNewsletterIdDelete = value
}
export const hideImageNewsletterDeleteModal = (state) => {
    state.showImageNewsletterDeleteModal = false
    console.log(state.updateImageNewsletterIdDelete);
    state.updateImageNewsletterIdDelete = null
}