<template>
    <div class="pr-6">  
        <div class="container mx-auto max-w-6xl pt-16 pb-6 space-y-8 px-2 md:px-4 xl:px-0">
            <div class="flex justify-between">
                <div class="px-3 border-l-8 border-red-600 my-auto py-2">
                    <label class="text-2xl text-gray-700 font-bold uppercase">
                        <!-- Review / Feedback -->{{$t('EUG-h3')}}
                    </label>
                </div>
                <div class="my-auto">
                    <a :href="base_url_viewAllFeedback" target="framename" class="flex justify-center text-center px-2 md:px-7 py-2 md:py-3 w-28 md:w-full bg-red-600 uppercase text-xs md:text-base border border-white text-white rounded-xl">
                        <!-- View All -->{{$t('view-all')}}
                    </a>
                </div>
            </div>

            <div class="container mx-auto mt-4">
                <div class=" glider-contain">
                    <div class="review-slider" v-on:mouseover="pauseReviewSlide" v-on:mouseleave="nextSlide">
                        <div class="flex" v-for="feedbackPublishList in getFeedbackPublishList" :key="feedbackPublishList.id">
                            <div class="p-4 w-full">
                                <div class="flex justify-center -mb-10 h-profileHeight">
                                    <img src="/images/profile02.jpg" class=" bg-white h-36 w-36 border border-gray-300 rounded-xl object-contain">
                                </div>
                                <div class="border border-b-2 shadow">
                                    <p class="px-4 pt-14 pb-4 h-feedbackHeight overflow-hidden text-sm">{{ feedbackPublishList.message }}
                                    </p>
                                    <div class="border-t flex px-3 py-3">
                                        <div class="w-8/12 flex flex-col text-left">
                                            <label class="text-cyan font-semibold">
                                                {{ feedbackPublishList.first_name }}
                                                {{ feedbackPublishList.last_name }}
                                            </label>
                                        </div>
                                        <div class="w-4/12 flex justify-end my-auto">
                                            <div class="flex space-x-1" v-for="n in parseInt(feedbackPublishList.star)">
                                                <svg class="fill-current text-yellow-400" xmlns="http://www.w3.org/2000/
                                                svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path 
                                                d="M0 0h24v24H0V0z" fill="none"/><path d="M12 17.27L18.18 21l-1.64-7.03L22 
                                                9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27z"/></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button aria-label="Previous" class="review-prev">
                        <svg class="w-10 text-gray-400 hover:text-gray-600 border absolute -bottom-6 right-20 bg-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                    </button>
                    <button aria-label="Next" class="review-next" id="clickNextSlide">
                        <svg class="w-10 text-gray-400 hover:text-gray-600 border absolute -bottom-6 right-4 bg-gray-100" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </button>
                    <div role="tablist" class="dots"></div> 
                </div> 
            </div>  
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default  ({
  data: function() {
    return {
        autoSlide:null,
        email:'',     
        first_name:'',
        last_name:'',
        star:'',
        base_url_viewAllFeedback:'',
    }
  },

  computed: {
    ...mapGetters({
        // getFeedbackList: 'feedbackReviews/getFeedbackList',
        getFeedbackPublishList: 'feedbackReviews/getFeedbackPublishList',
        getGeneral: 'settings/getGeneral',          
    }),
},
 
 methods: {       
    ...mapActions({
            // fetchFeedbackList: 'feedbackReviews/fetchFeedbackList',
            fetchFeedbackPublishList: 'feedbackReviews/fetchFeedbackPublishList',
            fetchGeneral: 'settings/fetchGeneral', 

            
        }),
    getFeedback() {
        this.fetchFeedbackPublishList().then((response)=>{
            if(response){
                this.nextSlide()
            }
        })
    },
    nextSlide() {
        window.glider('review-slider', 'review-prev', 'review-next', 1, 2, 3)
        this.autoSlide = setInterval(function () { 
           document.getElementById('clickNextSlide').click();
       }, 6000);
    },
    pauseReviewSlide(event) {  
        clearInterval(this.autoSlide);
    },

    fetchGeneralList(){             
        this.fetchGeneral().then((response) => {    
            this.base_url_viewAllFeedback = this.getGeneral.base_url + 'all-feedback';
        })
    },


    },
    mounted() {
        // setTimeout(function(){
        //     this.nextSlide  
        // }, 10);
        this.getFeedback(); 
        this.fetchGeneralList();

    },
});
</script>

<style>

</style>