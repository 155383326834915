export default [
{
	path: '/contact-us',
	component: () => import('../components/contactUsParent'),
	meta: {
		guest: false,
		needsAuth: true
	},
	children:[
				{
					path: '/contact-us',
					component: () => import('../components/ContactUs.vue'),
					name: 'contact-us',	
				},
				{
					path: '/contact-us/reply/:id',
					component: () => import('../components/partials/ReplySection.vue'),
					name: 'contact-reply',	
				},
		]
	
	},

]
