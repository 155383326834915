import { isEmpty } from 'lodash'

// add membership plan modelbox
export const addMembershipPlan = ({ commit }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-plan', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// ~~~~~~~~~
// membership-plans
export const fetchMembershipPlanByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/membership-plan/${id}`).then((response) => {
    response.data.data.scope_list.data.forEach((item) => {
        if (item.checked) {
            item.checked = true
        } else {
            item.checked = false
        }
    })   
        //console.log(response.data.data)
        return response.data.data
    })
}
//////
// membership-scope by id
export const fetchMembershipScopeByID = ({commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/membership-scope/${id}`).then((response) => {   
        return response.data
    })
}
// add membershipScope plan modelbox
export const addMembershipScope = ({ commit }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-scope', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// ~~~~~~~~~~

// list membership scope plan
export const fetchScopeList = ({commit}, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-scope' + query).then((response) => {          
        commit('setScopeList', response);    
    })
}
// ~~~~~~~~~

// list membership plan
export const fetchMembershipPlan = ({commit}, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/membership-plan' + query).then((response) => {     
    console.log(response);     
        commit('setMembershipPlanList', response);    
    })
}


export const updatedMembershipPlan = ({ commit },{ id, payload, context }) => {
    return window.axios.put(process.env.VUE_APP_JIIPA_API_URL + `api/membership-plan/${id}`, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


// delete membership plan
export const deleteMembershipPlan = ({ commit, state, dispatch }, id) => {
    id = state.membershipPlanIdDeleted
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/membership-plan/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
         dispatch("fetchMembershipPlan")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// export const hideEditMembershipPlan = ({commit}) => {
//     commit('showEditMembershipPlan', false)   
//     commit('resetEditMembershipPlanModelData')  
// }
// ~~~~~~~~~



// delete membership scope
export const deleteMembershipScope = ({ commit, state, dispatch }, id) => {
    id = state.membershipScopeIdDeleted
    return window.axios.delete(process.env.VUE_APP_JIIPA_API_URL + `api/membership-scope/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchScopeList")
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// edit update scope
export const updatedMembershipScope = ({ commit },{ id, payload, context }) => {
    return window.axios.put(process.env.VUE_APP_JIIPA_API_URL + `api/membership-scope/${id}`, payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// 