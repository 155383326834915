export default {
	isLoading: false,
	fullPage: true,
	DeleteMembership: true,
	isAuth: true,
	eventList: [],
	categoryList: [],
	DeleteList : null,
	editEvent :{
		event_title:'',
		event_category:'',
		event_date:'',
		event_location:'',
		event_description:'',
		event_cost:'',
		currency:'',
		file: null,
		s_token: process.env.VUE_APP_SSO_PUBLIC_KEY,
		c_token: process.env.VUE_APP_JIPPA_PUBLIC_KEY
	},
	showAddEvent:false,
	hideAddEvent:false,
	showEditEvent:false,
	hideEditEvent:false,
	showDeleteEvent:false,
	hideDeleteEvent:false,
	EventList: [],
	editEventId: null,
	updateImageEventIdDelete: null,
   showImageEventDeleteModal: false,
   hideImageEventDeleteModal: false,
} 