export default {
  state: {
    delateGalleryListModal: false,
    hideGalleryListModal: false,
    hideGalleryList: false,
    showViewGallery: false
  },
  getters: {
    delateGalleryListModal(state) {
      return state.delateGalleryListModal
    },
    hideGalleryListModal(state) {
      return state.hideGalleryListModal
    },
    hideGalleryList(state) {
      return state.hideGalleryList
    },
    showViewGallery(state) {
      return state.showViewGallery
    }
  },
  mutations: {
    delateGalleryListModal(state, flag) {
      state.delateGalleryListModal = flag
    },
    hideGalleryListModal(state) {
      state.delateGalleryListModal = false
    },
    hideGalleryList(state, flag) {
      state.hideGalleryList = flag
    },
    showViewGallery(state) {
      state.hideGalleryList = true,
      state.showViewGallery = false
    },
  }   
}