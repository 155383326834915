export const setUser = (state, data) => {
    state.users = data
};

export const setAgreement = (state, data) => {
    state.agreements = data
};

export const setRefferals = (state, data) => {
    state.refferals = data
};

export const setEvent = (state, data) => {
    state.event = data
};