
export const setEventList = (state, data) => {
	// console.log(data);

	return state.eventList = data
}

export const setCategoryList = (state, data) => {
	console.log(data);

	return state.categoryList = data
}


export const showAddEvent= (state, flag)=> {
	state.showAddEvent = flag
}
export const  hideAddEvent = (state)=> {
	state.showAddEvent = false
}

export const showEditEvent = (state, flag) => {
	state.showEditEvent = flag
}

export const hideEditEvent = (state) => {
	state.showEditEvent = false
}

export const showDeleteEvent = (state, {flag , value})=> {
	state.showDeleteEvent = flag
	state.DeleteList = value
	
}
export const hideDeleteEvent = (state) => {
	state.showDeleteEvent = false
}

export const setShowEditEvent = (state, data) => {
	state.editEventId = data.id
	state.editEvent.event_title = data.event_title
	state.editEvent.event_category = {
		id : 0,
		category_name : data.event_category,
		status:1
	}
	state.editEvent.event_date = data.event_date
	state.editEvent.event_location = data.event_location
	state.editEvent.event_description = data.event_description
	state.editEvent.event_cost = data.event_cost
	state.editEvent.currency = data.currency
	state.editEvent.images = data.images.data
	
}


export const showImageEventDeleteModal = (state, {flag, value}) => {
    state.showImageEventDeleteModal = flag
    state.updateImageEventIdDelete = value
}
export const hideImageEventDeleteModal = (state) => {
    state.showImageEventDeleteModal = false
    state.updateImageEventIdDelete = null
}

