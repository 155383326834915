 <template>
    <div>
        <v-idle
            @idle="onidle"
            @remind="onremind"
            :loop="true"
            :reminders="[60]"
            :wait="5"
        :duration="600" />
        <div class="header fixed w-full z-40 top-0" id="navbar">
            <Header/>   
        </div>
        <div class="flex pt-24 main" v-if="isAuth">
            <!-- tab and large screen -->
            <Sidebar class="fixed h-full" :class="[sidebarMenuClass]" id="sidebar" />
            <div :class="[sidebarMenuClass]"></div>
            
            <!-- ~~~~ -->
            <!-- mobile screen -->
            <div>   
                <MobileSidebar  class="w-full block md:hidden"/>
            </div>
            <!-- ~~~~~~ -->
            <div class="router-container" :class="[containerClass]" id="container-box">
                <router-view/>
                <ReviewsSlide /> 
                <AdminFooter/> 
            </div>
        </div>

        <div v-if="!isAuth">
            <router-view />
            <ReviewsSlide />    
            <Footer />  
        </div>
          
        <Alert />    
        <!--  Logout popup start  -->
        <div v-if="logoutReminder">
            <div class="opacity-40 fixed inset-0 z-40 bg-black"></div>
            <div  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none justify-center items-center flex rounded-3xl">
                <div class="relative w-1/3 my-6 rounded-3xl">
                    <!-- content -->
                    <div class="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-3xl">
                        <!--  header  -->
                        <div class="flex items-center justify-between px-7 py-2 border-b border-solid border-blueGray-200 rounded-t-2xl text-white bg-red-800">
                            <h3 class="pl-4 text-xl font-semibold">Logout Reminder</h3>
                        </div>
                        <!--  body  -->
                        <div class="bg-red-100 py-6 px-10 rounded-b-2xl">
                            <label class="flex text-base text-center font-semibold ">You will be logout in next 60 seconds</label>
                            <div class="flex justify-center item-center gap-4 pt-7 pb-7">
                                <button @click="keepMeLogin" class="w-36 uppercase bg-transparent font-bold text-black px-2 py-2 text-xs border border-gray-800 rounded-full w-28">Kepp Me Login</button>
                                <button @click="logout" class="uppercase bg-red-500 font-bold text-white px-2 py-2 text-xs rounded-full w-28 outline-none focus:outline-none darkRed flex justify-center items-center">Logout</button>

                            </div>
                        </div>
                        <!--  end body  -->
                    </div>
                </div>
            </div>
        </div>
        <!--  Logout popup end  -->
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import Header from './components/partial/Header.vue'
import Sidebar from './components/partial/Sidebar.vue'
import MobileSidebar from './components/partial/MobileSidebar.vue'
import ReviewsSlide from './app/feedbackReviews/components/ReviewsSlide.vue'
import Footer from './components/partial/Footer.vue'
import Alert from './components/partial/Alert.vue'
import AdminFooter from './components/partial/AdminFooter.vue'
import FeedbackReviews from './app/feedbackReviews/components/FeedbackReviews.vue'
import localforage from 'localforage'
import { isEmpty } from 'lodash'


export default {
    data: function() {
    return {
        logoutReminder: false,
        sidebarMenu: true,
        sidebarMenuClass: 'w-sideWidth 2xl:w-2/12 md:block hidden',
        containerClass: 'w-full md:w-sideWidth2 2xl:w-10/12',
        // footerClass: 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0',
        // reviewClass: 'max-w-6xl 2xl:max-w-full pl-customPadding',
    }
  },
  components: {
    Header,    
    Alert,
    Sidebar,
    MobileSidebar,
    ReviewsSlide,    
    Footer,
    AdminFooter,
  },
  computed: mapState([
    'isAuth',
    'isCollapse',
  ]),

  methods: {
    keepMeLogin() {
        this.logoutReminder = false;
    },
    async logout() {
        if(this.getToken()) {
            this.$store.dispatch('auth/logout').then(() => {
                this.logoutReminder = false;
                this.$router.replace({ name: 'Sign-In' })
            }).catch((error) => {
                this.logoutReminder = false;
                this.$router.replace({ name: 'Sign-In' });
                return false;
            });
        }
    },
    async onidle() {
        if(this.getToken()) {
            this.logout();
        }
    },
    getToken() {
        return localforage.getItem('authtoken').then((token) => {
            if (isEmpty(token)) {
                return Promise.reject('NO_STORAGE_TOKEN')
            }
            return Promise.resolve(token)
        })
    },
    onremind(time) {
        // alert seconds remaining to 00:00
        if(this.getToken() && !this.logoutReminder) {
            if(localStorage.getItem('localforage/jiipa_admin/authtoken')){
                this.logoutReminder = true;
                console.log('User will logout in next '+time+' seconds');
            }else{
                this.logoutReminder = false;
                this.$router.replace({ name: 'Sign-In' });
            }
            
        }
    },
     collapseSidebar: function() {
             this.sidebarMenu = !this.sidebarMenu
             if (this.sidebarMenu){
                this.sidebarMenuClass = 'w-sideWidth 2xl:w-2/12 md:block hidden';
                this.containerClass = 'w-full md:w-sideWidth2 2xl:w-10/12';
                // this.footerClass = 'w-full md:pl-1 xl:pl-footerPadding 2xl:ml-0';
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customPadding';
             }
             else {
                this.sidebarMenuClass = 'w-16 md:block hidden';
                this.containerClass = 'w-full lg:w-12/12 pl-10';                
                // this.footerClass = 'w-full ';                
                // this.reviewClass = 'max-w-6xl 2xl:max-w-full xl:pl-customCollapsePadding';                
             }
        }       
  },
  
    mounted() {   
        let hideScroll = window.pageYOffset;
        window.onscroll = function() {
        let currentScrollPos = window.pageYOffset;
        if (window.pageYOffset < 3 ) {

            if(document.getElementById("navbar")){
                document.getElementById("navbar").style.top = "0";
            }
           if(document.getElementById("sidebar")){
                document.getElementById("sidebar").style.top = "121px";
            }
            if(document.getElementById("sidebarLg")){
                document.getElementById("sidebarLg").style.top = "160px";
            }            
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.top = "2.8rem";
            }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.position = "static";
            }
        } else {
             if(document.getElementById("sidebar")){
                document.getElementById("sidebar").style.top = "0";
            }
            if(document.getElementById("sidebarLg")){
                document.getElementById("sidebarLg").style.top = "0";
            }
            if(document.getElementById("navbar")){
                document.getElementById("navbar").style.top = "-165px";
            }           
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.top = "0";
            }
            if(document.getElementById("title_fixed")){
                document.getElementById("title_fixed").style.position = "sticky";
            }
        }

        hideScroll = currentScrollPos;
    }

  }
};
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: 0.1s all ease-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

#navbar {
  transition: top 0.3s;
}

#sidebar {
  transition: top 0.3s;
}


.router-container{
    transition: 0.3s ease;
  }
</style>

