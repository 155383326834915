const cityData = ({ commit, context}, query) => {
  query = query && (query != 'undefined') ? query : 'a';
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/get-city/" + query).then((response) => {
      commit("SET_CITY_DATA", response.data);
      return response.data;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};

const countryData = ({ commit, context}, query = 'some') => {
  
  return window.axios.get(process.env.VUE_APP_SSO_API_URL  + "api/countries/" + query).then((response) => {
      commit("SET_COUNTRY_DATA", response.data);
      return true;
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      context.errors = error.response.data.errors;
      return false;
    });   
};


// get phonecode data api
const phoneData = ({ commit }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/phone-code').then((response) => {
    // commit("setAuthenticated", true);
    return response.data.data;
})
  .catch((error) => {
      
    // errors = error.response.data.errors;
    return false;
});
};

const getCountryState = ({ commit}, { payload, context}) => {
  return window.axios({
      method: 'GET',
      url: process.env.VUE_APP_SSO_API_URL + `api/get-country-state-by-city/` + payload.id,
  }).then((response) => {
      return response.data;
  }).catch((error) => {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
      context.errors = error.response.data
      return false
  })
};

const languagesList = ({ commit, context}) => {
  
  return window.axios.get(process.env.VUE_APP_JIIPA_API_URL  + "api/languages-list").then((response) => {
      if(response.data.success){
        return response.data.data;
      }else{
        return false;
      }
    }).catch((error) => {
      commit("SET_ALERT", "warning", { root: true });
      commit("SET_ALERT_MESSAGE", "Something went wrong City", { root: true });
      return false;
    });   
};

export default {
  cityData,
  countryData,
  phoneData,
  getCountryState,
  languagesList
}