export const showAddNewAgreement = (state, flag) => {
	state.showAddNewAgreement = flag
}
export const hideAddNewAgreement = (state) => {
	state.showAddNewAgreement = false
}

// ~~~~~~EditAgreement
export const showEditAgreement = (state, {flag, value}) => {
	state.showEditAgreement = flag
	state.agreementIdEdit = value
}
export const hideEditAgreement = (state) => {
	state.showEditAgreement = false
	// state.agreementIdEdit = null

}
// ~~~~

export const showDeleteModel = (state, { flag, value }) => {
	state.showDeleteModel = flag
	state.agreementIdDeleted = value
}
export const hideDeleteModel = (state) => {
	state.showDeleteModel = false
	state.agreementIdDeleted = null	
}

export const showAddPayment = (state, flag) => {
	state.showAddPayment = flag
}
export const hideAddPayment = (state) => {
	state.showAddPayment = false
}

export const showDeletePayment = (state, flag) => {
	state.showDeletePayment = flag
}
export const hideDeletePayment = (state) => {
	state.showDeletePayment = false
}

export const setAgreementList = (state, data) => {
	state.agreementList = data
}

export const setUserRole = (state, data) => {
	state.userRoles = data
}


// check agreement
export const showCheckAgreementModel = (state, flag) => {
	state.showCheckAgreementModel = flag
}
export const hideCheckAgreementModel = (state) => {
	state.showCheckAgreementModel = false
}
// 

// language get
export const setLanguage = (state, data) => {
    state.language = data;
}
//

// publish slide
export const setFeedbackPublishList = (state, data) => {
	return state.feedbackPublishList = data
}

export const fetchEditAutoEmailId = (state, {value}) => {
	state.editAutoEmail = value
}

// successfulyy model box
export const showModalBoxSuccessfully = (state, flag) => {
	state.showModalBoxSuccessfully = flag
}
export const hideModalBoxSuccessfully = (state) => {
	state.showModalBoxSuccessfully = false
}
////
////// not successfulyy model box
export const showModalBoxNotSuccessfully = (state, flag) => {
	state.showModalBoxNotSuccessfully = flag
}
export const hideModalBoxNotSuccessfully = (state) => {
	state.showModalBoxNotSuccessfully = false
}
/////

////intergation fetch discount list
export const setDiscountList = (state, data) => {
    state.discountList.data = data.data
    state.discountList.links = data.links
	state.discountList.meta = data.meta
}

export const showPromoPlanDelete = (state, { flag, value }) => {
	state.showPromoPlanDelete = flag
	state.promoPlanDeleted = value
}
export const hidePromoPlanDelete = (state) => {
	state.showPromoPlanDelete = false
	state.promoPlanDeleted = false	
}
/////////////////

export const setPromoPlanById = (state, data) => {
    state.discountList.plan = data;
};
//////////////////////
export const setDiscountPlanId = (state, value) => {
    console.log("plan")
    console.log(state, value)
    state.discountId = value;
};
// export const setDiscountPlanId = (state, {flag, value}) => {
//     state.discountId = value;
// };

export const setGeneral = (state, data) => {
	console.log('datfdsfdsfdsfda');
	console.log(data);
    state.general.data = data;
  }

  // slack integration
  	export const showDeleteSlackFunction = (state, flag) => {
		state.showDeleteSlackFunction = flag
	}
/////
// slack integration
  	export const showAddNewSlack = (state, flag) => {
		state.showAddNewSlack = flag
	}
	export const showDeleteSlackAndModule = (state, flag) => {
		state.showDeleteSlackAndModule = flag
	}
///////