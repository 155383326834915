export default [
	{
		path: '/user-list',
		component: () => import('../components/UserList.vue'),
		name: 'user-list',
		meta: {		
			guest: false,
			needsAuth: true,	
		}
	},
	{
		path: '/add-user',
		component: () => import('../components/AddUser.vue'),
		name: 'add-user',
		meta: {			
			guest: false,
			needsAuth: true,
		}
	},

	{
		path: '/edit-user/:id',
		component: () => import('../components/EditUser.vue'),
		name: 'edit-user',
		meta: {		
			guest: false,
			needsAuth: true,	
		}
	},
	{
		path: '/view-user/:id',
		component: () => import('../components/ViewUser.vue'),
		name: 'view-user',
		meta: {			
			guest: false,
			needsAuth: true,
		}
	},
]