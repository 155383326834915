export default {
    showAddNewAgreement: false,
    hideAddNewAgreement: false,

    showEditAgreement: false,
    hideEditAgreement: false,

    showDeleteModel: false,
    hideDeleteModel: false,

    showAddPayment: false,
    hideAddPayment: false,

    showDeletePayment: false,
    hideDeletePayment: false,

    agreementList: [],
    agreementIdDeleted: null,   	
    userRoles: [],
    agreementIdEdit: null,   
    language: [],

    showCheckAgreementModel: false,
    hideCheckAgreementModel: false,	

    editAutoEmail :[],

    showModalBoxSuccessfully: false,
    hideModalBoxSuccessfully: false,

    showModalBoxNotSuccessfully: false,
    hideModalBoxNotSuccessfully: false,

    discountList: {        
        plan: [],
        discountId: '',
        data: null,
        links: null,
        meta: null
    }, 
    showPromoPlanDelete: false,
    hidePromoPlanDelete: false,
    promoPlanDeleted: null,

    general:[],
    
    showDeleteSlackFunction: false,

    showAddNewSlack: false,
    showDeleteSlackAndModule: false,
}