import { createApp } from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

import localforage from 'localforage'

import gliderHelper from './plugins/js/glider-helper.js'
window.glider = gliderHelper

import App from './App.vue'
import router from './router'
import store from './store/store.js'
import CKEditor from '@ckeditor/ckeditor5-vue'
import './index.css'
import './plugins/js/glider.min.js'
import './plugins/css/glider.min.css'
import i18n from './i18n'

import VueApexCharts from "vue3-apexcharts";
import Vidle from 'v-idle-3'

window.axios = axios.create({
    baseURL: process.env.VUE_APP_SSO_API_URL || '',
    headers: {
        Accept: 'application/json'
    }
})

localforage.config({
    driver: localforage.LOCALSTORAGE,
    storeName: 'jiipa_admin'
})  

window.alertDuration = process.env.VUE_APP_ALERT_DURATION || 2000


store.dispatch('auth/setToken').then(() => {
    store.dispatch('auth/fetchUser').then(() => {

    }).catch(() => {
        store.dispatch('auth/clearAuth')
        router.replace({ name: 'Sign-In'})
    })
}).catch(() => {
    store.dispatch('auth/clearAuth')
})

const app = createApp(App).use(i18n).use(i18n).use(i18n).use(i18n).use(i18n).use(i18n).use(store).use(router).use(VueApexCharts).use(CKEditor).use('router').use(Vidle);


app.mount('#app')


// VUE_APP_JIPPA_API_URL