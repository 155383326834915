export const setContactList = (state, data) => {
    state.contactList.data = data.data
    state.contactList.links = data.links
    state.contactList.meta = data.meta
}
export const showLoader = (state, flag) => {
    state.showLoader = flag
}

export const setContactListByID = (state, value) => {
    console.log("contactus")
    console.log(state, value)
    state.contactListByID = value;
};

export const setReplyListByID = (state, value) => {
    console.log("contactus")
    console.log(state, value)
    state.replyListByID = value;
};