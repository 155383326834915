export default [	
{
	path: '/promotion-messages',
	component: () => import('../components/promotionMessages.vue'),
	name: 'promotion-messages',
	meta: {	
		guest: false,
		needsAuth: true	
	}
},
{
      path: "/create-pramotion-messages",
      component: () => import("../components/partials/createPramotionMessages.vue"),
      name: "create-pramotion-messages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },

    {
      path: "/view-pramotion-messages",
      component: () => import("../components/partials/viewPramotionMessages.vue"),
      name: "view-pramotion-messages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },

    {
      path: "/edit-pramotion-messages",
      component: () => import("../components/partials/editPramotionMessages.vue"),
      name: "edit-pramotion-messages",
      meta: {
        guest: false,
        needsAuth: true,
      },
    },
]