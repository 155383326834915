<template>
	<div>
		<div  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex rounded-3xl">
			<div class="relative px-6 lg:px-20 xl:px-0 w-full md:w-2/3 xl:w-1/3 my-6 rounded-3xl">
				<!-- content -->
				<div class="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none rounded-3xl">
					<!--  header  -->
					<div class="flex items-center justify-between px-7 py-2 border-b border-solid border-blueGray-200 rounded-t-3xl text-white bg-darkRed">
						<h3 class="pl-4 text-xl font-semibold">
							<!-- Delete Feedback -->{{$t('delete-feedback')}}
						</h3>
						<div>
							<button class="" type="button" @click="showDeleteFeedback(false)">
								<svg class="mt-3 mx-2 bg-white rounded-full" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#DA5352"/></svg>
							</button>
						</div>
					</div>
					<!--  body  -->

					<!-- Delate popup -->
					<div class="bg-settingBg py-6 px-10 rounded-b-3xl">
						<label class="flex text-base justify-center font-semibold "><!-- Are You Sure, You Want To delete this feedback -->{{$t('want-to-delete-feedback')}}</label>

						<div class="flex justify-center item-center gap-4 pt-7 pb-7">
							<button @click="showDeleteFeedback(false)" class="uppercase bg-transparent font-bold text-black text-xs border border-gray-800 rounded-full w-28 h-9 flex justify-center items-center "><!-- No -->{{$t('CALDR-h3')}}</button>
							<button type="button" :disabled="isDeleteLoader"  @click="deleteModel()" class="uppercase rounded-full w-28 h-9 flex justify-center items-center" 
							:class="[isDeleteLoader ? 'cursor-not-allowed bg-gray-100 border border-gray-300' : 'text-white font-semibold bg-greenBg text-xs border']">
								<svg v-if="isDeleteLoader" class="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
								<span v-if="!isDeleteLoader"><!-- Yes -->{{$t('CALDR-h4')}}</span>
							</button>
						</div>
					</div>
					<!--  Delate popup end  -->
				</div>
			</div>
		</div>
		<div class="opacity-40 fixed inset-0 z-40 bg-black"></div>
	</div>	
</template>

<script>
// @ is an alias to /src
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

export default {
	data: function() {
		return {
     	isDeleteLoader: false,
		}
	},
	
	computed: {
		...mapGetters({
	 		getFeedbackPublishById: 'feedbackReviews/getFeedbackPublishById',
	 	}),
	},
	methods: {
		...mapMutations({
			'showDeleteFeedback':'feedbackReviews/showDeleteFeedback',
		}),
		...mapActions({
			'deleteFeedback':'feedbackReviews/deleteFeedback',
		}),

		deleteModel() {
	      	this.isDeleteLoader = true;
	 		this.deleteFeedback(this.getFeedbackPublishById).then((success)=>{
	 			if(success){
	 				this.showDeleteFeedback(false);
	            	this.isDeleteLoader = false
	 			}else {
	            	this.isDeleteLoader = false
	        	}
	 		})  		
	 	},
	},
};
</script>