// export const getViewFeedback = (state) => {
//       return state.showViewFeedback
// }

// delete model
export const getDeleteFeedback = (state) => {
      return state.showDeleteFeedback
}

////
export const feedbackIdDeleted = (state) => {
      return state.feedbackIdDeleted
}
///
export const getFeedbackList = (state) => {
      return state.feedbackList
}

export const getFeedbackById = (state) => {
      return state.feedbackById
}

// publish slide
export const getFeedbackPublishList = (state) => {
      return state.feedbackPublishList
}
////