import vue from 'vue'
import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import FinanceAudit from "./modules/FinanceAudit.js";
import MembershipPlan from "./modules/MembershipPlan.js";
// import Gallery from "./modules/Gallery.js";
// import RoleAndPermission from "./modules/RoleAndPermission.js";
import Gallery from "./modules/Gallery.js";
import UserList from "./modules/UserList.js";
import DeleteContract from "./modules/DeleteContract.js";
import ErrorLog from "./modules/ErrorLog.js";
import auth from "../app/auth/vuex/index.js";
import Events from "../app/Events/vuex/index.js";
// import membershipPlan from "../app/membershipPlan/vuex";
import membershipPlan from "../app/membershipPlan/vuex/index.js";
import settings from "../app/settings/vuex/index.js";
import gallery from "../app/gallery/vuex/index.js";
import roleAndPermission from "../app/roleAndPermission/vuex/index.js";
import feedbackReviews from "../app/feedbackReviews/vuex/index.js";
import referrals from "../app/referrals/vuex/index.js";
import profile from "../app/profile/vuex/index.js";
import newsletter from "../app/newsletter/vuex/index.js";
import userList from "../app/userList/vuex/index.js";
import Dashboard from "../app/Dashboard/vuex/index.js";
import EmailTemplate from "../app/EmailTemplate/vuex/index.js";
import contactUs from "../app/contactUs/vuex/index.js";
import localization from "../app/localization/vuex/index.js";
import agreements from "../app/agreements/vuex/index.js";

export default createStore({
    state,
    getters, 
    mutations,
    actions,
    modules: {
        FinanceAudit: FinanceAudit,
        MembershipPlan: MembershipPlan,
        localization: localization,
        agreements: agreements,
        gallery: gallery,
        // RoleAndPermission: RoleAndPermission,
        Gallery: Gallery,
        roleAndPermission: roleAndPermission,
        UserList: UserList,
        Events: Events,
        DeleteContract: DeleteContract,
        ErrorLog: ErrorLog,
        membershipPlan: membershipPlan,
        auth:auth,
        settings:settings,
        feedbackReviews:feedbackReviews,
        referrals:referrals,
        profile:profile,
        newsletter:newsletter,
        userList:userList,
        Dashboard:Dashboard,
        EmailTemplate:EmailTemplate,
        contactUs:contactUs,
    }
})

