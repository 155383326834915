export const getUser = (state) => {
	return state.users
}

export const getAgreement = (state) => {
    return state.agreements
};

export const getRefferals = (state) => {
	return state.refferals
}

export const getEvent = (state) => {
    return state.event
};