
import localforage from 'localforage'
import { isEmpty } from 'lodash'

export const SET_ROLE_LIST = (state, data) => {
    state.myRoles = data.data
}

export const setPhoneCode = (state, data) => {
    state.phoneCode = data
}

export const SET_CITY_DATA = (state, data) => {
    state.cities = data
}

export const setUserList = (state, data) => {
    state.listUsers.data = data.data.data
    state.listUsers.links = data.data.links
    state.listUsers.meta = data.data.meta
}

export const hideDeleteUser = (state) => {
    state.showUserDeleteModel = false
    state.userBeingDeleted = null
}