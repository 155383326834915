export default {
	contactList: {
		List: [],
		data: null,
        links: null,
        meta: null,
	},
    contactListByID: '',        
    replyListByID: '',        
   	showLoader: false,  	
	
}
