import { createRouter, createWebHistory } from 'vue-router'
import { routes as routes } from '../app/index'


import PageNotFound from '../views/PageNotFound.vue'
import beforeEach from './beforeEach'


const otherRoutes = [

{
  path: '/error-log',
  name: 'error-log',
  component: () => import('../views/ErrorLog')
},

{
  path: '/error-pop-up',
  name: 'error-pop-up',
  component: () => import('../views/ErrorPopUp')
},


{
  path: '/customer-non-admin-profile',
  name: 'customer-non-admin-profile',
  component: () => import('../views/CustomerNonAdminProfile.vue')

},

  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: PageNotFound
  },

  ]


  for (let i = 0; i < otherRoutes.length; i++){
    routes.push(otherRoutes[i]);
  }

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
  })

  router.beforeEach(beforeEach)
  export default router
