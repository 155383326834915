// Put your main states here
export default {
    isLoading: false,
    fullPage: true,
    DeleteMembership: true,
    isAuth: false,
    locale: 'en',
    // color: 'red',
    // loader: 'dots',
    // opacity: 0.8
    
    alerts: {
        default: false,
        success: false,
        warning: false,
        info: false,
        message: null
    },
}

