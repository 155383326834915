export const setMembershipPlanList = (state, data) => {
	state.PlanList.data = data.data.data
    state.PlanList.meta = data.data.meta
    state.PlanList.links = data.data.links
}
export const setScopeList = (state, data) => {
	state.scopeList.pageData = data.data.data
	console.log(data);
	console.log("hisds");
    state.scopeList.pagelinks = data.data.data.links
	state.scopeList.meta = data.data.meta
}

export const showAddMembershipPlan = (state, flag) => {
	state.showAddMembershipPlan = flag
}
export const hideAddMembershipPlan = (state) => {
	state.showAddMembershipPlan = false
}

// ~~~~~~Edit Membership plan
export const showEditMembershipPlan = (state, {flag, value}) => {
	state.showEditMembershipPlan = flag
	state.membershipPlanIdEdit = value
}
export const hideEditMembershipPlan = (state) => {
	state.showEditMembershipPlan = false
}
// ~~~~
// ~~~~~~view Membership plan
export const showViewMembershipPlan = (state, {flag, value}) => {
	state.showViewMembershipPlan = flag
	state.membershipPlanIdEdit = value
}
export const hideViewMembershipPlan = (state) => {
	state.showViewMembershipPlan = false
}
// ~~~~
///
export const showDeleteMembershipPlan = (state, { flag, value }) => {
	state.showDeleteMembershipPlan = flag
	state.membershipPlanIdDeleted = value
}
export const hideDeleteMembershipPlan = (state) => {
	state.showDeleteMembershipPlan = false
	state.membershipPlanIdDeleted = null	
}
///////

export const showAddMembershipScope = (state, flag) => {
	state.showAddMembershipScope = flag
}
export const hideAddMembershipScope = (state) => {
	state.showAddMembershipScope = false
}

// scope delete
export const showDeleteMembershipScope = (state, {flag, value}) => {
	state.showDeleteMembershipScope = flag
	state.membershipScopeIdDeleted = value
}
export const hideDeleteMembershipScope = (state) => {
	state.showDeleteMembershipScope = false
	state.membershipScopeIdDeleted = null	
}

// ~~~~~~Edit Membership scope model
export const showEditMembershipScope = (state, {flag, value}) => {
	state.showEditMembershipScope = flag
	state.membershipScopeIdEdit = value
}
export const hideEditMembershipScope = (state) => {
	state.showEditMembershipScope = false
}
// ~~~~