/**
 * @param  {className} - container class name
 * @param  {[smSlides]} - small size slides number
 * @param  {[mdSlides]} - medium size slides number
 * @param  {[lgSlides]} - large size slides number
 * @return {[void]}
 */
export default function initiateGlider(className, prevClassName, nextClassName, smSlides, mdSlides, lgSlides)
{
	// default values
	smSlides = smSlides || 1
	mdSlides = mdSlides || 2
	lgSlides = lgSlides || 3

	new Glider(document.querySelector(`.${className}`), {
		slidesToShow: smSlides,
		slidesToScroll: 1,
		draggable: true,
		rewind: true,		
		inFinite: true,
		dots: '#dots',
		arrows: { 
			prev: `.${prevClassName}`,
			next: `.${nextClassName}`
		},
		responsive: [

			{
				breakpoint: 775,
				settings: {
					slidesToShow: mdSlides,
					slidesToScroll: 1,        
					itemWidth: 150,
					duration: 0.25
				}
			},
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: lgSlides,
					slidesToScroll: 1,
					itemWidth: 150,
					duration: 0.25
				}
			}
		]
	});
}

