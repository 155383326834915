export default [
	{
		path : '/',
		component: () => import('../components/SignIn.vue'),
		name : 'Sign-In',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

	{
		path : '/forgot-password',
		component: () => import('../components/ForgotPassword.vue'),
		name : 'Forgot-Password',
		meta: {
			guest: true,
			needsAuth: false
		}
	},


	{
		path : '/reset-password',
		component: () => import('../components/ResetPassword.vue'),
		name : 'ResetPassword',
		meta: {
			guest: true,
			needsAuth: false
		}
	},

	{
		path : '/change-password',
		component: () => import('../components/ChangePassword.vue'),
		name : 'change-password',
	},
]