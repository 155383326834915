export default { 
    user: {
        token: null,
        authenticated: false,
        data: null,
        roleName: null,
        roleSlug: null,
    }, 
    phoneCode: [],
    cities: [],
    userListForCurrentUser: [],
    listUsers: {
        data: null,
        links: null,
        meta: null
    },
    myRoles: [],
}
