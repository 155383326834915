<template>
    <div> 

        <div class="absolute top-24 md:top-20 ml-3">
            <button @click="showSidebar = !showSidebar">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7"></path></svg>
            </button>
        </div>
        <div class="container mx-auto h-full">
            <transition name="slide">
                <section v-if="showSidebar" class="bg-greenBg h-full overflow-auto rounded-r-xl py-4 text-white absolute left-0 inset-y-0 max-w-2xl z-50 ">
                    <div class="flex px-10">
                        <div class="w-8/12 font-semibold flex justify-end 2xl:mx-16">
                            <router-link :to="{name:'my-profile'}" @click="showSidebar = !showSidebar" class="my-auto text-xl hover:underline hover:text-activeBG "><!-- My Profile -->{{$t('CADL-h3')}}</router-link>
                        </div>
                        <div class="w-4/12 flex justify-end">
                            <button @click="showSidebar = !showSidebar">
                                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>        
                        </div>      
                    </div>

                    <label class="mt-3 mb-5 space-y-3">
                        <div class="mt-4 w-full flex justify-center mb-2">
                            <img :src="myProfile.profile_pic" class="profile object-cover rounded-full border w-28 h-28 mx-auto flex justify-center">
                        </div>
                        <router-link :to="{name:'my-profile'}" @click="showSidebar = !showSidebar" class="space-y-0.5 rounded flex flex-col text-center hover:bg-activeBG mx-6" 
                        :class="{ 'bg-activeBG': isActive('my-profile') || isActive('edit-profile')}">
                             <label class="text-sm font-semibold">{{myProfile.name  + "  " + myProfile.last_name}}</label>
                             <label class="text-small font-semibold">{{myProfile.designation ? myProfile.designation.sso_designation : ''}}</label>
                             <label class="text-small font-semibold">{{myProfile.user_role}}</label>
                        </router-link>
                    </label>
                  

                    <div class="border-b-4 mx-6 my-4"></div>                    

                    <div class="space-y-2">
                        <div>
                            <router-link :to="{name:'dashboard'}" @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>
                                    <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 22.5h20" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.75 4.5v18h4.5v-18c0-1.1-.45-2-1.8-2h-.9c-1.35 0-1.8.9-1.8 2ZM3 10.5v12h4v-12c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2ZM17 15.5v7h4v-7c0-1.1-.4-2-1.6-2h-.8c-1.2 0-1.6.9-1.6 2Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Dashboard -->{{$t('dashboard')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link :to="{name:'role-and-permission'}" @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer"
                            :class="{ 'bg-activeBG': isActive('role-and-permission') || isActive('role-add') || isActive('role-edit') || isActive('role-view')}">
                                <label>
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 4.15C2 2.72 2.72 2 4.15 2h4.3c1.43 0 2.15.72 2.15 2.15V6M6.7 18H4.15C2.72 18 2 17.28 2 15.85V8.04" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M17.37 8.42v11.16c0 1.61-.8 2.42-2.41 2.42H9.12c-1.61 0-2.42-.81-2.42-2.42V8.42C6.7 6.81 7.51 6 9.12 6h5.84c1.61 0 2.41.81 2.41 2.42Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.4 6V4.15c0-1.43.72-2.15 2.15-2.15h4.3C21.28 2 22 2.72 22 4.15v11.7c0 1.43-.72 2.15-2.15 2.15h-2.48M10 11h4M10 14h4M12 22v-3" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Roles & permission -->{{$t('CADL-h8')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link :to="{name:'agreements'}"  @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer"
                            :class="{ 'bg-activeBG': isActive('agreements') || isActive('edit-agreements')}">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 8c0-4-2-6-6-6H8C4 2 2 4 2 8v13c0 .55.45 1 1 1h13c4 0 6-2 6-6v-4" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9.5h2M7 9.5h5M7 14.5h7" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Agreements -->{{$t('CADL-h9')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link to="#" class="group relative uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 8c0-4-2-6-6-6H8C4 2 2 4 2 8v13c0 .55.45 1 1 1h13c4 0 6-2 6-6v-4" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9.5h2M7 9.5h5M7 14.5h7" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>         
                                </label>                                
                                <label class="my-auto"><!-- User List -->{{$t('CADL-h10')}}</label>
                                 <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                        <!-- coming soon... -->{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div>
                            </router-link>
                        </div>

                        <div>
                            <router-link to="#" class="group relative uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21 11.75H3c-.96 0-1.75-.79-1.75-1.75V6.68c0-.68.47-1.37 1.1-1.62l9-3.6c.38-.15.92-.15 1.3 0l9 3.6c.63.25 1.1.95 1.1 1.62V10c0 .96-.79 1.75-1.75 1.75Zm-9-8.91c-.04 0-.08 0-.1.01l-8.99 3.6c-.06.03-.16.16-.16.23V10c0 .14.11.25.25.25h18c.14 0 .25-.11.25-.25V6.68c0-.07-.09-.2-.16-.23l-9-3.6c-.02-.01-.05-.01-.09-.01Zm10 19.91H2c-.41 0-.75-.34-.75-.75v-3c0-.96.79-1.75 1.75-1.75h18c.96 0 1.75.79 1.75 1.75v3c0 .41-.34.75-.75.75Zm-19.25-1.5h18.5V19c0-.14-.11-.25-.25-.25H3c-.14 0-.25.11-.25.25v2.25Z" fill="#fff"/><path d="M4 18.75c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm4 0c-.41 0-.75-.34-.75-.75v-7c0-.41.34-.75.75-.75s.75.34.75.75v7c0 .41-.34.75-.75.75Zm3 4H1c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h22c.41 0 .75.34.75.75s-.34.75-.75.75ZM12 9.25c-1.24 0-2.25-1.01-2.25-2.25S10.76 4.75 12 4.75 14.25 5.76 14.25 7 13.24 9.25 12 9.25Zm0-3c-.41 0-.75.34-.75.75s.34.75.75.75.75-.34.75-.75-.34-.75-.75-.75Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto">
                                    <!-- Finance & Audit -->
                                    {{$t('side-mod-head-2-con-5')}}
                                </label>
                                <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                        <!-- coming soon... -->{{$t('coming-soon')}}
                                        <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                    </div>
                            </router-link>
                        </div>

                        <div>
                            <router-link :to="{name: 'localization'}"  @click="showSidebar = !showSidebar" class="menu_active uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.79 21.26-.72-.72A3.576 3.576 0 0 0 18.08 15c-1.98 0-3.58 1.6-3.58 3.58a3.576 3.576 0 0 0 5.54 2.99l.72.72a.728.728 0 0 0 1.03-1.03ZM2.03 15.13c0 .03-.02.07-.02.1a9.494 9.494 0 0 0 4.26 4.26c.03 0 .07-.02.1-.02-.34-1.16-.6-2.35-.79-3.54-1.2-.2-2.39-.46-3.55-.8ZM19.07 6.93a9.5 9.5 0 0 0-4.49-4.49c.36 1.19.66 2.41.86 3.63 1.22.2 2.44.49 3.63.86ZM1.93 6.93c1.2-.36 2.42-.66 3.64-.86.2-1.19.45-2.37.79-3.53-.03 0-.07-.02-.1-.02-1.88.93-3.41 2.5-4.33 4.41ZM13.82 5.86c-.24-1.3-.54-2.6-.97-3.86-.02-.07-.02-.13-.03-.21-.74-.18-1.52-.29-2.32-.29-.81 0-1.58.11-2.33.3-.01.07 0 .13-.02.21-.42 1.26-.73 2.55-.97 3.85 2.21-.24 4.43-.24 6.64 0ZM5.36 7.68c-1.31.24-2.59.55-3.86.97-.07.02-.13.02-.2.03C1.11 9.42 1 10.2 1 11c0 .81.11 1.58.3 2.33.07.01.13 0 .21.02 1.26.42 2.55.73 3.86.97-.25-2.21-.25-4.43-.01-6.64ZM19.7 8.68c-.07 0-.13-.01-.21-.03-1.26-.42-2.56-.73-3.86-.97.25 2.21.25 4.43 0 6.63 1.3-.24 2.6-.54 3.86-.97.07-.02.13-.01.21-.02.18-.75.3-1.52.3-2.33 0-.79-.11-1.56-.3-2.31ZM7.18 16.14c.24 1.31.54 2.6.97 3.86.02.07.01.13.02.21.75.18 1.52.29 2.33.29.8 0 1.58-.11 2.32-.3.01-.07.01-.13.03-.21.42-1.26.73-2.55.97-3.86-1.1.12-2.21.21-3.32.21-1.11 0-2.22-.08-3.32-.2ZM6.95 7.45c-.3 2.36-.3 4.74 0 7.11 2.36.3 4.74.3 7.11 0 .3-2.36.3-4.74 0-7.11-2.37-.3-4.75-.3-7.11 0Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto"><!-- Localization -->{{$t('side-mod-head-2-con-6')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link to="#" class="group relative uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label> 
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.3 7.92v5.15c0 3.08-1.76 4.4-4.4 4.4H6.11c-.45 0-.88-.04-1.28-.13-.25-.04-.49-.11-.71-.19-1.5-.56-2.41-1.86-2.41-4.08V7.92c0-3.08 1.76-4.4 4.4-4.4h8.79c2.24 0 3.85.95 4.28 3.12.07.4.12.81.12 1.28Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M22.301 10.92v5.15c0 3.08-1.76 4.4-4.4 4.4h-8.79c-.74 0-1.41-.1-1.99-.32-1.19-.44-2-1.35-2.29-2.81.4.09.83.13 1.28.13h8.79c2.64 0 4.4-1.32 4.4-4.4V7.92c0-.47-.04-.89-.12-1.28 1.9.4 3.12 1.74 3.12 4.28Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.498 13.14a2.64 2.64 0 1 0 0-5.28 2.64 2.64 0 0 0 0 5.28ZM4.78 8.3v4.4m11.442-4.4v4.4" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Membership Plans -->{{$t('side-mod-head-2-con-7')}}</label>
                                <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                    <!-- coming soon... -->{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div>
                            </router-link>
                        </div>

                        <!-- <div>
                            <router-link to="#"  @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.66 10.438-.98 4.18c-.84 3.61-2.5 5.07-5.62 4.77-.5-.04-1.04-.13-1.62-.27l-1.68-.4c-4.17-.99-5.46-3.05-4.48-7.23l.98-4.19c.2-.85.44-1.59.74-2.2 1.17-2.42 3.16-3.07 6.5-2.28l1.67.39c4.19.98 5.47 3.05 4.49 7.23Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M15.06 19.392c-.62.42-1.4.77-2.35 1.08l-1.58.52c-3.97 1.28-6.06.21-7.35-3.76l-1.28-3.95c-1.28-3.97-.22-6.07 3.75-7.35l1.58-.52c.41-.13.8-.24 1.17-.31-.3.61-.54 1.35-.74 2.2l-.98 4.19c-.98 4.18.31 6.24 4.48 7.23l1.68.4c.58.14 1.12.23 1.62.27ZM12.64 8.531l4.85 1.23M11.66 12.398l2.9.74" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto">Custom fields{{$t('side-mod-head-2-con-8')}}</label>
                            </router-link>
                        </div>  -->           

                        <div>
                            <router-link :to="{name:'feedback-reviews'}"  @click="showSidebar = !showSidebar" class="menu_active uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>  
                                    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.356 12.969c.657.26 1.257.605 1.797 1.035.54.429 1.001.929 1.386 1.495a6.898 6.898 0 0 1 1.211 3.877V20H12.5v-.626a5.563 5.563 0 0 0-1.65-3.974 5.791 5.791 0 0 0-1.787-1.2 5.569 5.569 0 0 0-2.189-.45A5.564 5.564 0 0 0 2.9 15.4a5.8 5.8 0 0 0-1.2 1.787 5.56 5.56 0 0 0-.45 2.187V20H0v-.626A6.748 6.748 0 0 1 1.211 15.5a7.026 7.026 0 0 1 3.185-2.529 4.277 4.277 0 0 1-.793-.704 4.51 4.51 0 0 1-.596-.86 4.202 4.202 0 0 1-.371-.976A4.78 4.78 0 0 1 2.5 9.374c0-.604.114-1.171.343-1.698A4.421 4.421 0 0 1 5.166 5.35a4.381 4.381 0 0 1 3.408-.01A4.422 4.422 0 0 1 10.9 7.666c.37.87.448 1.836.224 2.754a4.246 4.246 0 0 1-.38.977c-.168.31-.367.6-.596.869-.228.267-.493.5-.791.703ZM6.874 12.5c.43 0 .835-.081 1.212-.243a3.134 3.134 0 0 0 1.66-1.661c.17-.385.254-.792.254-1.22a3.053 3.053 0 0 0-.919-2.197 3.404 3.404 0 0 0-.995-.675 2.926 2.926 0 0 0-1.212-.254c-.428 0-.833.081-1.21.243-.752.32-1.35.92-1.671 1.671a3.048 3.048 0 0 0-.243 1.212c0 .428.081.833.243 1.21.164.377.386.71.666.995a3.097 3.097 0 0 0 2.217.919h-.002ZM20 0v10h-2.5l-3.75 3.75V10H12.5V8.75H15v1.983l1.983-1.983h1.767v-7.5H6.25v2.246c-.21.026-.418.06-.626.098-.212.04-.421.1-.624.176V0h15Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto"><!-- Feedback/Reviews -->{{$t('side-mod-head-2-con-9')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link to="#" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.467 7.371c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81a3.828 3.828 0 0 0-1.414 2.97v2.943c0 .494.401.895.896.895h5.91a.896.896 0 0 0 .896-.895V10.34a3.828 3.828 0 0 0-1.414-2.97ZM12 2.36a2.05 2.05 0 0 1 2.048 2.048A2.05 2.05 0 0 1 12 6.455a2.05 2.05 0 0 1-2.048-2.048A2.05 2.05 0 0 1 12 2.36Zm2.09 10.03H9.97v-2.012c0-.976.786-1.92 1.751-2.061a2.058 2.058 0 0 1 2.369 2.025v2.048ZM6.288 20.625c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81A3.828 3.828 0 0 0 0 23.595v2.943c0 .494.401.895.896.895h5.91a.896.896 0 0 0 .896-.895v-2.944a3.828 3.828 0 0 0-1.414-2.97Zm-2.426-5.018a2.05 2.05 0 0 1 2.048 2.048 2.05 2.05 0 0 1-2.048 2.048 2.05 2.05 0 0 1-2.047-2.048 2.05 2.05 0 0 1 2.047-2.048ZM5.91 25.643H1.791v-2.012c0-.976.785-1.921 1.751-2.061a2.059 2.059 0 0 1 2.368 2.025v2.048ZM22.586 20.625c2.79-2.28 1.17-6.809-2.437-6.809-3.608 0-5.227 4.53-2.437 6.81a3.828 3.828 0 0 0-1.413 2.97v2.943c0 .494.4.895.895.895h5.91A.896.896 0 0 0 24 26.54v-2.944a3.828 3.828 0 0 0-1.414-2.97Zm-2.448-5.018a2.05 2.05 0 0 1 2.047 2.048 2.05 2.05 0 0 1-2.047 2.048 2.05 2.05 0 0 1-2.048-2.048 2.05 2.05 0 0 1 2.048-2.048Zm2.071 10.036h-4.12v-2.012c0-.976.786-1.921 1.752-2.061a2.059 2.059 0 0 1 2.368 2.025v2.048ZM15.286 21.346l-2.39-2.39v-3.38a.896.896 0 0 0-1.791 0v3.38l-2.39 2.39a.896.896 0 1 0 1.266 1.266l2.02-2.02 2.018 2.02a.895.895 0 1 0 1.267-1.266Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto"><!-- Referrals -->{{$t('CADL-h11')}}</label>
                                <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                    <!-- coming soon... -->{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div>
                            </router-link>
                        </div>

                        <div>
                            <router-link :to="{name:'contact-us'}"  @click="showSidebar = !showSidebar" class=" uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer" :class="{ 'bg-activeBG': isActive('contact-us') || isActive('contact-reply')}">
                                <label>   
                                    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M22 3H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zm0 16H2V5h20v14zm-2.99-1.01L21 16l-1.51-2h-1.64c-.22-.63-.35-1.3-.35-2s.13-1.37.35-2h1.64L21 8l-1.99-1.99A7.474 7.474 0 0 0 16.28 10c-.18.64-.28 1.31-.28 2s.1 1.36.28 2a7.512 7.512 0 0 0 2.73 3.99zM9 12c1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3 1.35 3 3 3zm0-4c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm6 8.59c0-2.5-3.97-3.58-6-3.58s-6 1.08-6 3.58V18h12v-1.41zM5.48 16c.74-.5 2.22-1 3.52-1s2.77.49 3.52 1H5.48z"/></svg>
                                </label>
                                <label class="my-auto"><!-- Contact Us -->{{$t('eh-nb-title5')}}</label>
                            </router-link>
                        </div> 

                        <div>
                            <router-link to="#" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 22h8c4.02 0 4.74-1.61 4.95-3.57l.75-8C21.97 7.99 21.27 6 17 6H7c-4.27 0-4.97 1.99-4.7 4.43l.75 8C3.26 20.39 3.98 22 8 22ZM8 6v-.8C8 3.43 8 2 11.2 2h1.6C16 2 16 3.43 16 5.2V6" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M14 13v1.02c0 1.09-.01 1.98-2 1.98-1.98 0-2-.88-2-1.97V13c0-1 0-1 1-1h2c1 0 1 0 1 1ZM21.65 11A16.484 16.484 0 0 1 14 14.02M2.62 11.27c2.25 1.54 4.79 2.47 7.38 2.76" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Services -->{{$t('CADL-h12')}}</label>
                            </router-link>
                        </div>

                        <div>
                            <router-link :to="{name:'events'}"  @click="showSidebar = !showSidebar" class="menu_active uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2v3M16 2v3M3.5 9.09h17M3 13.01V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.995 13.7h.01M8.294 13.7h.01M8.294 16.7h.01" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Events & Exhibitions -->{{$t('CADL-h13')}}</label>
                            </router-link>
                        </div> 

                        <div>
                            <router-link :to="{name:'newsletter'}"  @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label> 
                                    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path fill="none" d="M0 0h24v24H0z"/><path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/><path d="M14 17H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"/></svg>
                                </label>
                                <label class="my-auto"><!-- Newsletter -->{{$t('CADL-h14')}}</label>
                                </router-link>
                            </div>

                        <div>
                            <router-link :to="{name:'gallery'}"  @click="showSidebar = !showSidebar" class="menu_active uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg class="fill-current text-white" xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 4v12H8V4h12m0-2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 9.67 1.69 2.26 2.48-3.1L19 15H9zM2 6v14c0 1.1.9 2 2 2h14v-2H4V6H2z"/></svg>
                                </label>
                                <label class="my-auto"><!-- Gallery -->{{$t('eh-nb-title6')}}</label>
                            </router-link>
                        </div>

                       <!--  <div>
                            <router-link to="#" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>  
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m21.805 20.868-.918.937a.677.677 0 0 1-.469.175.677.677 0 0 1-.469-.175L17 18.839a4.995 4.995 0 0 1-2.5.663c-1.38 0-2.559-.487-3.535-1.463-.977-.976-1.465-2.153-1.465-3.532 0-1.378.488-2.556 1.465-3.531C11.94 10 13.12 9.512 14.5 9.512c1.38 0 2.559.488 3.535 1.464.977.975 1.465 2.152 1.465 3.531 0 .885-.221 1.71-.664 2.478l2.969 2.966c.13.13.195.283.195.459a.627.627 0 0 1-.195.458ZM14.51 10.761c-1.035 0-1.92.364-2.656 1.093a3.583 3.583 0 0 0-1.104 2.644c0 1.034.368 1.918 1.104 2.653.735.735 1.62 1.103 2.656 1.103a3.59 3.59 0 0 0 2.646-1.103 3.632 3.632 0 0 0 1.094-2.653c0-1.035-.365-1.916-1.094-2.644-.729-.729-1.611-1.093-2.646-1.093Zm1.24-2.38v-.118c0-.35.124-.647.371-.887.248-.241.54-.361.879-.361h2.5c.352 0 .651.12.898.36.248.241.372.537.372.888v6.244h-.02c0-1.496-.472-2.82-1.416-3.97-.944-1.152-2.139-1.87-3.584-2.157ZM9.5 10.76V5.766c0-.351.124-.647.371-.888.247-.24.54-.36.879-.36h2.5c.352 0 .648.12.889.36.24.24.361.537.361.888v2.497a6.06 6.06 0 0 0-2.813.674A6.395 6.395 0 0 0 9.5 10.76Zm0 7.473a6.306 6.306 0 0 0 2.188 1.844 6.06 6.06 0 0 0 2.812.673h1.25c0 .338-.12.631-.361.878S14.852 22 14.5 22H3.25c-.339 0-.632-.124-.879-.37A1.21 1.21 0 0 1 2 20.74c0-.344.12-.637.361-.878.241-.24.537-.36.889-.36V3.248c0-.338.124-.631.371-.878S4.161 2 4.5 2H7c.352 0 .648.124.889.37.24.248.361.54.361.879v16.253H9.5v-1.268Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto">SEO{{$t('side-mod-head-2-con-18')}}</label>
                            </router-link>
                        </div> -->

                        <div>
                            <router-link to="#" class="group relative uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                    <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.885 3.49c-.346-.654-1.422-.654-1.768 0l-9 17A.999.999 0 0 0 3 21.96h18a.998.998 0 0 0 .883-1.468l-9-17ZM13 18.959h-2v-2h2v2Zm-2-4v-5h2v5h-2Z" fill="#fff"/></svg>
                                </label>
                                <label class="my-auto"><!-- Error Logs -->{{$t('side-mod-head-2-con-16')}}</label>
                                <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                    <!-- coming soon... -->{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div>
                            </router-link>
                        </div>

                        <div>
                            <router-link to="#" class="group relative uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>  
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 12H18L15 21L9 3L6 12H2" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </label>
                                <label class="my-auto"><!-- Activity Logs -->{{$t('CADL-h15')}}</label>
                                <div class="opacity-0 w-28 bg-black text-white text-center text-xs rounded-lg py-2 -top-1 absolute z-10 group-hover:opacity-100">
                                    <!-- coming soon... -->{{$t('coming-soon')}}
                                    <svg class="absolute inset-x-12 flex justify-center items-center text-center text-black h-2 w-2 top-full" x="0px" y="0px" viewBox="0 0 255 255" xml:space="preserve"><polygon class="fill-current" points="0,0 127.5,127.5 255,0"/></svg>
                                </div>
                            </router-link>
                        </div>

                        <!-- <div>
                            <router-link to="#"  @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                                <label>   
                                 <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.43C2 3.14 3.14 2 5.43 2H10c2.29 0 3.43 1.14 3.43 3.43M8 16H5.43C3.14 16 2 14.86 2 12.57V9.98M18.57 22H14c-2.29 0-3.43-1.14-3.43-3.43v-7.14C10.57 9.14 11.71 8 14 8h4.57C20.86 8 22 9.14 22 11.43v7.14c0 2.29-1.14 3.43-3.43 3.43ZM14.87 15h3.26M16.5 16.631v-3.26" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                             </label>
                             <label class="my-auto">CMS (content management){{$t('side-mod-head-2-con-19')}}</label>
                            </router-link>
                        </div> -->

                        <div>
                            <router-link :to="{name:'settings-general'}" @click="showSidebar = !showSidebar" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer" :class="{ 'bg-activeBG': isActive('settings-general') || isActive('settings-dashboard') || isActive('auto-email-dispatch')|| isActive('finance-audit') || isActive('file-server') || isActive('discount-promotion-plan') || isActive('add-promotion-plan') || isActive('view-promotion-plan') || isActive('edit-promotion-plan') || isActive('contract-agreements') || isActive('slack-integration') || isActive('slack-function') || isActive('settings-slack-add') || isActive('settings-slack-view') || isActive('settings-slack-edit')}">
                            <label>   
                                <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 6.5h-6M6 6.5H2M10 10a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM22 17.5h-4M8 17.5H2M14 21a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Z" stroke="#fff" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            </label>
                            <label class="my-auto"><!-- Settings -->{{$t('CADL-h16')}}</label>
                            </router-link>
                        </div>
                    </div>

                    <div class="flex justify-center mt-12 mb-6">
                        <router-link to="#"  v-on:click="logout" class="uppercase font-semibold tracking-wider text-xs flex space-x-4 xl:space-x-10 py-3 px-6 hover:bg-activeBG cursor-pointer">
                            <label>
                                <svg width="25" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.74 22.27h-.13c-4.44 0-6.58-1.75-6.95-5.67-.04-.41.26-.78.68-.82.4-.04.78.27.82.68.29 3.14 1.77 4.31 5.46 4.31h.13c4.07 0 5.51-1.44 5.51-5.51V8.74c0-4.07-1.44-5.51-5.51-5.51h-.13c-3.71 0-5.19 1.19-5.46 4.39-.05.41-.4.72-.82.68a.751.751 0 0 1-.69-.81c.34-3.98 2.49-5.76 6.96-5.76h.13c4.91 0 7.01 2.1 7.01 7.01v6.52c0 4.91-2.1 7.01-7.01 7.01Z" fill="#fff"/><path d="M15.5 12.75H4.12c-.41 0-.75-.34-.75-.75s.34-.75.75-.75H15.5c.41 0 .75.34.75.75s-.34.75-.75.75Z" fill="#fff"/><path d="M6.35 16.1c-.19 0-.38-.07-.53-.22l-3.35-3.35a.754.754 0 0 1 0-1.06l3.35-3.35c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L4.06 12l2.82 2.82c.29.29.29.77 0 1.06-.14.15-.34.22-.53.22Z" fill="#fff"/></svg>
                            </label>
                            <label class="capitalize"><!-- Sign Out -->{{$t('CADL-h18')}}</label>
                        </router-link>
                    </div>
                </section>
            </transition>
        </div>       
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'

export default  ({
 data: function() {
  return {
    showSidebar:false,   
    myProfile: {
      name:"",
      user_role: "",
      last_name: "",
      designation:"",
      profile_pic:"",
    }    
  }
},
components: {
},
 computed: {
    
  }, 
    methods: {
        ...mapActions({
            getUserRolesData: 'profile/getUserRolesData',
            fetchUserById: 'profile/fetchUserById',
        }),
        ...mapGetters({
            user: 'auth/user',
            getMyRoleList: 'profile/getMyRoleList',
            getEditUserData: 'profile/getEditUserData'
        }),
     closeDeleteModel(){
          this.showSidebar = false
        },
        logout() {
          this.$store.dispatch('auth/logout').then(() => {
            this.$router.replace({ name: 'Sign-In' })
          })
        },

        formatNumbers() {
            if(this.checkExist) {
                clearInterval(this.checkExist);
            }
        },

        getUserData(){
            let user = JSON.parse(localStorage.getItem('user_data'));
            this.myProfile.name = this.myProfile.name ? this.myProfile.name : user.name;
            this.myProfile.last_name = this.myProfile.last_name ? this.myProfile.last_name : user.last_name;
            this.myProfile.profile_pic = this.myProfile.profile_pic ? this.myProfile.profile_pic : user.profile_pic;
            this.myProfile.designation = this.myProfile.designation ? this.myProfile.designation : user.designation;
            this.myProfile.user_role = this.myProfile.user_role ? this.myProfile.user_role : user.user_role;
        },
        isActive(routeName) {
          return this.$route.name === routeName;
        },
    },

    mounted() {
       this.myProfile = this.getEditUserData()
       this.getUserData();
      this.$store.commit('isAuthenticated', true)
    },
});
</script>

<style scoped>
.slide-enter-active {
  transition: 0.6s;
   left: 0;
}
.slide-leave-active{
  transition: 0.5s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.slide{
    left: 100%; 
    transform: translate(-100%, 0);
}

.menu_active.router-link-exact-active{
    background-color: #CCAFAF;
    color: #ffffff;
    text-decoration: none;
}
</style>