export default [
	{
		path : '/gallery',
		component: () => import('../components/GalleryParent.vue'),
		meta: {
			guest: false,
			needsAuth: true
		},
		children:[
				{
					path : '/gallery',
					component: () => import('../components/Gallery.vue'),
					name : 'gallery',
				},
				{
					path : '/add-gallery',
					component: () => import('../components/AddGallery.vue'),
					name : 'add-gallery',
				},
				{
					path : '/view-gallery/:id',
					component: () => import('../components/ViewGallery.vue'),
					name : 'view-gallery',
				},
				{
					path : '/edit-gallery/:id',
					component: () => import('../components/EditGallery.vue'),
					name : 'edit-gallery',
				},
			]
	},
	
	// {
	// 	path : '/view-gallery/:id',
	// 	component: () => import('../components/ViewGallery.vue'),
	// 	name : 'view-gallery',
	// 	meta: {
	// 		guest: false,
	// 		needsAuth: true
	// 	}
	// },
	// {
	// 	path : '/edit-gallery/:id',
	// 	component: () => import('../components/EditGallery.vue'),
	// 	name : 'edit-gallery',
	// 	meta: { 
	// 		guest: false,
	// 		needsAuth: true
	// 	}
	// },
 ]