export const user = (state) => {
	return state.user
}

export const authenticated = (state) => {
	return state.user.authenticated
}

export const roleName = (state) => {
	console.log("HERHEHR")
	return state.user.data.details.roles[0].show_name
}

export const loggedInUser = (state) => {
  return state.user.data;
};