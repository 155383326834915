export default {
  state: {
    showDeleteUser: false,
    hideDeleteUser: false,
    userInclusionModel: false,
    hideUserInclusionModel: false,
},
   
  getters: {
    showDeleteUser(state) {
      return state.showDeleteUser
    },
    hideDeleteUser(state) {
      return state.hideDeleteUser
    },
    userInclusionModel(state) {
      return state.userInclusionModel
    },
    hideUserInclusionModel(state) {
      return state.hideUserInclusionModel
    }
  },
  mutations: {
    showDeleteUser(state, flag) {
      state.showDeleteUser = flag
    },
    hideDeleteUser(state) {
      state.showDeleteUser = false
    },
    userInclusionModel(state, flag) {
      return state.userInclusionModel
    },
    hideUserInclusionModel(state) {
      return state.userInclusionModel = false
    }
  },
}