export default {
	showAddReview: false,
   	hideAddReview: false,

   	showDeleteModel: false,
   	hideDeleteModel: false,

   	showInviteReferrals: false,
   	hideInviteReferrals: false,

   	referralList: {
		data: [],
		links: [],
		referralId:[]
	},
	idDeleteReferralPlan:null,
}