// contact us
export const fetchContactList = ({commit}, query = '') => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/contact-us' + query).then((response) => {          
        commit('setContactList', response.data);   
        return response.data
    })
}
// 

export const fetchContactListByID = ({commit, state}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/contact-us/${id}`).then((response) => {   
        commit('setContactListByID', response.data);
        console.log(response.data);
        return response.data
    })
}

export const replyMessage = ({ commit }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_JIIPA_API_URL + 'api/contact-us-reply', payload).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        console.log(response.data)
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchReplyListByID = ({commit, state}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/contact-us-reply-by/${id}`).then((response) => {   
        commit('setReplyListByID', response.data.data);
        console.log(response.data.data);
        return response.data.data
    })
}

