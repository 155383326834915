import { createI18n } from 'vue-i18n'
import { isEmpty } from 'lodash'

function loadLocaleMessages() {
  let currentLocale = localStorage.getItem('localforage/jiipa_admin/locale')
  if (isEmpty(currentLocale))
      currentLocale = 'jp'
  // try {
    return require(`./locales/${currentLocale.replace(/['"]+/g, '')}.json`).default
  // } catch (error) {
  //   localStorage.setItem('localforage/jiipa_admin/locale', 'en')
  //   alert("Sorry for the inconviniences. Language not supported yet")
  //   return require(`./locales/en.json`).default
  // }
} 

function getCurrentLocale() {
  let currentLocale = 'jp'
  if (!isEmpty(localStorage.getItem('localforage/jiipa_admin/locale'))) {
    currentLocale = localStorage.getItem('localforage/jiipa_admin/locale').replace(/['"]+/g, '')
  }
  return currentLocale
}
export default createI18n({
  locale: getCurrentLocale() || 'jp',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages:  loadLocaleMessages()
})