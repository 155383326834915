export const getGalleryList = (state) => {
	return state.galleryList
}
export const showGalleryDeleteModal = (state) => {
	return state.showGalleryDeleteModal
}

export const hideGalleryDeleteModal = (state) => {
	return state.hideGalleryDeleteModal
}

export const presentGalleryIdDelete = (state) => {
	return state.presentGalleryIdDelete
}

export const getGalleryIdEdit = (state) => {
	return state.editIdGallery
}

export const getGalleryById = (state) => {
      return state.galleryById
}

export const getUpdateImageGalleryIdDelete = (state) => {
      return state.showImageGalleryDeleteModal
}
export const hideImageGalleryDeleteModal = (state) => {
      return state.hideImageGalleryDeleteModal
}

// export const getUpdateImageGalleryIdDelete = (state) => {
//       return state.getUpdateImageGalleryIdDelete
// }