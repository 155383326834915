
// ADD event 
export const addEvent = ({ commit }, { payload, context }) => {
  
    return window.axios.post( process.env.VUE_APP_JIIPA_API_URL + 'api/event/add', payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
      

        // window.location = "events"
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}


// list event

export const fetchEventList = ({ commit }) => {
   
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + 'api/event/list').then((response) => {
        console.log(response);
        
        commit('setEventList', response.data.data);
        
    })
}

// list category

export const fetchCategoryList = ({ commit }, query = '') => {
    var  url ='';
    if(query && query.searchQuery && (query.searchQuery != 'undefined')){
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/event-category-list?name='+ query.searchQuery;
    }else{
        url = process.env.VUE_APP_JIIPA_API_URL + 'api/event-category-list';
    }
    return window.axios.get(url).then((response) => {
        commit('setCategoryList', response.data.data);
        return response.data;
        
    })
}

// delete event

export const deletelist = ({ commit, state, dispatch,payload}, id) => {
    id = state.DeleteList
    return window.axios.delete( process.env.VUE_APP_JIIPA_API_URL + `api/event/delete/${id}`, payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        commit('setAuthenticated', true);
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchEventList")
        // window.location = "events"
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// view event


export const fetchEventById = ({ commit}, id) => {
    return window.axios.get(process.env.VUE_APP_JIIPA_API_URL + `api/event/edit/${id}`).then((response) => {        
        commit('setShowEditEvent', response.data.data)
        console.log(response.data);
        commit('showEditEvent', true);   
      })
}


// update event

export const updateEvent = ({ commit }, { payload,  context , id}) => {
    console.log(payload);
    return window.axios.post( process.env.VUE_APP_JIIPA_API_URL + `api/event/update/${id}`, payload,{
        config: { headers: { "Content-Type": "multipart/form-data" } },
    }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        // window.location = "events"        
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

// delete images



export const DeleteImageEvent = ({ commit, dispatch, state }, id) => {
    id = id ? id : state.updateImageEventIdDelete
    return window.axios.delete (process.env.VUE_APP_JIIPA_API_URL + `api/event-images-delete/${id}`).then((response) => {
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        dispatch("fetchEventList")
        
       // window.location.reload();
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true }) 
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        console.log()
        context.errors = error.response.data

        return false
    })
}




