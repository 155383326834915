export const getMembershipPlanList = (state) => {
	return state.PlanList.data
}
export const getMemberListLinks = (state) => {
	return state.PlanList.links
}
export const getMemberListMeta = (state) => {
	return state.PlanList.meta
}

export const getScopeList = (state) => {
	return state.scopeList.pageData
}
export const getScopeListLinks = (state) => {
	return state.scopeList.pagelinks
}
export const getScopeListMeta = (state) => {
	return state.scopeList.meta
}

export const getShowAddMembershipPlan = (state) => {
	return state.showAddMembershipPlan
}
export const hideAddMembershipPlan = (state) => {
	return state.hideAddMembershipPlan
}

// ~~~EditAgreement
export const getShowEditMembershipPlan = (state) => {
	return state.showEditMembershipPlan
}
export const hideEditMembershipPlan = (state) => {
	return state.hideEditMembershipPlan
}
////////
// ~~~view model
export const getShowViewMembershipPlan = (state) => {
	return state.showViewMembershipPlan
}
export const hideViewMembershipPlan = (state) => {
	return state.hideViewMembershipPlan
}
//////
export const getMembershipPlanIdEdit = (state) => {
	return state.membershipPlanIdEdit
}

export const membershipPlanIdDeleted = (state) => {
	return state.membershipPlanIdDeleted
}

export const getShowDeleteMembershipPlan = (state) => {
	return state.showDeleteMembershipPlan
}
export const hideDeleteMembershipPlan = (state) => {
	return state.hideDeleteMembershipPlan
}
///////

export const getShowAddMembershipScope = (state) => {
	return state.showAddMembershipScope
}
export const hideAddMembershipScope = (state) => {
	return state.hideAddMembershipScope
}

// scope delete
export const getShowDeleteMembershipScope = (state) => {
	return state.showDeleteMembershipScope
}
export const hideDeleteMembershipScope = (state) => {
	return state.hideDeleteMembershipScope
}
/////

///// scope edit show
export const getMembershipScopeIdEdit = (state) => {
	return state.membershipScopeIdEdit
}

export const getShowEditMembershipScope = (state) => {
	return state.showEditMembershipScope
}
export const hideEditMembershipScope = (state) => {
	return state.hideEditMembershipScope
}
/////

// export const getMemberListLinks = (state) => {
// 	return state.PlanList.links
// }

