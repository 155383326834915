import Events from './Events/routes'
import auth from './auth/routes'
import profile from './profile/routes'
import membershipPlan from './membershipPlan/routes'
import settings from './settings/routes'
import gallery from './gallery/routes'
import roleAndPermission from './roleAndPermission/routes'
import feedbackReviews from './feedbackReviews/routes'
import financeAndAudit from './financeAndAudit/routes'
import referrals from './referrals/routes'
import newsletter from './newsletter/routes'
import userList from './userList/routes' 
import promotionMessages from './promotionMessages/routes' 
import Dashboard from './Dashboard/routes' 
import EmailTemplate from './EmailTemplate/routes' 
import contactUs from './contactUs/routes' 
import localization from './localization/routes' 
import agreements from './agreements/routes' 

export default [ 
	...auth, 
	...profile, 
	...membershipPlan, 
	...settings, 
	...gallery, 
	...roleAndPermission, 
	...feedbackReviews, 
	...Events, ...financeAndAudit, 
	...referrals, 
	...newsletter, 
	...userList, 
	...Dashboard, 
	...EmailTemplate, 
	...promotionMessages,
	...contactUs,
	...localization,
	...agreements,
	]




