import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const setToken = ({ commit, dispatch }, token) => {
    if (isEmpty(token)) {
        return dispatch('checkTokenExists').then((token) => {
            setHttpToken(token)
        })
    }
    commit('setToken', token)
    setHttpToken(token)
}

export const fetchUser = ({ commit }) => {
    return window.axios.get('api/user/details').then((response) => {
        commit('setAuthenticated', true)
        commit('setUserData', response.data.data)
        commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
    });
   
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }

        return Promise.resolve(token)
    })
}

export const checkIfRoleSelected = ({commit}, context) => {
    commit('CHECK_ROLE_SELECTION', context)
}

export const clearAuth = ({commit}) => {
    commit('setAuthenticated', false)
    commit('setUserData', null)
    commit('setToken', null)
    setHttpToken(null)
    setHttpBaseUrl(null)

    commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
}

export const setHttpBaseUrlAfterLogin = (token) => {

    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }

        setHttpBaseUrl(token)
        return Promise.resolve(token)
    })
}

export const addRole = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post (process.env.VUE_APP_SSO_API_URL + "api/role/add", payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchRoleListCopy = ({ commit, dispatch }) => {
    return window.axios.get (process.env.VUE_APP_SSO_API_URL + 'api/get-role/' + process.env.VUE_APP_PRODUCT_CODE_ROLE).then((response) => {
        commit('setAuthenticated', true);
        commit('setRolesListCopy', response.data.roles);
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false
    })
}

export const fetchRoleList = ({ commit, dispatch }, query = '') => {
    return window.axios.get (process.env.VUE_APP_SSO_API_URL + 'api/my-role/list' + query).then((response) => {
        commit('setAuthenticated', true);
        commit('setRolesList', response.data);
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false
    })
}

export const getUserRolesData = ({ commit, dispatch }) => {
  return window.axios.get(process.env.VUE_APP_SSO_API_URL + 'api/my-roles').then((response) => {
    commit('SET_ROLE_LIST', response)
    return true
  }).catch((error) => {
    dispatch("auth/errorHandler",error.response.status,{ root:true });
    if (error.response.data.message != '') {
      commit('SET_ALERT', 'warning', { root: true })
      commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
    }
      return false
  })
}

export const deleteRole = ({ commit, state, dispatch }, id) => {
    id = state.currentIdBeingDeleted
    return window.axios.delete (process.env.VUE_APP_SSO_API_URL + `api/role/delete/${id}`).then((response) => {
        commit('setAuthenticated', true);
        if(response.data.success){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        }else{
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        }
        dispatch("fetchRoleList")
        return response.data.success
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        context.errors = error.response.data
        return false
    })
}

export const editRoleData = ({ commit, state, dispatch }, id) => {
    return window.axios.get (process.env.VUE_APP_SSO_API_URL + `api/get-role-with-permissions/${id}`).then((response) => {
        commit('setRolesEditData', response.data);
        return response;
    }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const updateRole = ({commit, dispatch}, { payload, context, id }) => {
    return window.axios({
        method: 'post',
        url: process.env.VUE_APP_SSO_API_URL + `api/role/update/${id}`,
        data: {show_name:payload.show_name, name:payload.name, permissions:payload.permissions, tenant_uuid : process.env.VUE_APP_ADMIN_UUID},
        }).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        return response.data.success
        }).catch((error) => {
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        context.errors = error.response.data
        return false
    })
}

export const fetchPermissionCategoryList  = ({commit, dispatch}, id = null ) => {
    return window.axios({
        method: 'get',
        url: process.env.VUE_APP_SSO_API_URL + 'api/permissions-category/' + process.env.VUE_APP_PRODUCT_CODE_ROLE,
        
    }).then((allPermission) => {
        let allPermissions =  allPermission.data.permissions
        if (id != null) {
            dispatch('editRoleData', id).then((userPermissions) => {
                let userPermission = userPermissions.data.roles.permissions.map(a => a.name)
                dispatch('getFilteredPermissionList', {allPerm: allPermissions, userPerm: userPermission})
            })
        } else {
            dispatch('getFilteredPermissionList', {allPerm: allPermissions, userPerm: null})
        }
    }).catch((error) => {
        console.log('e',error);
        dispatch("auth/errorHandler",error.response.status,{ root:true });
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const getFilteredPermissionList = ({commit}, payload ) => {
    let allPermissions = payload.allPerm;
    let userPermission = payload.userPerm;
    let newAllPermission = [];
    for (var permission in allPermissions) {
        let key = 0;
        newAllPermission[permission] = [];
        for (var singlePermission in allPermissions[permission]) {
            let permitted = false;
            if (userPermission != null && userPermission.includes(allPermissions[permission][singlePermission])) {
                permitted = true;
            }   
            // allPermissions[permission][key] = {
            //     name: allPermissions[permission][singlePermission],
            //     permitted: permitted
            // }   
            if(allPermissions[permission][singlePermission].includes('approve')){
                newAllPermission[permission][0] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('add')){
                newAllPermission[permission][1] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('edit')){
                newAllPermission[permission][2] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('list-')){
                newAllPermission[permission][3] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('view-')){
                newAllPermission[permission][4] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            if(allPermissions[permission][singlePermission].includes('delete')){
                newAllPermission[permission][5] = {
                    name: allPermissions[permission][singlePermission],
                    permitted: permitted
                }
            }
            key++
        }
    }
    var object = Object.assign({}, newAllPermission);
    commit('setPermissionCategoryList',object);
}