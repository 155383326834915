export default [
{
	path: '/finance-and-audit',
	component: () => import('../components/FinanceAndAudit.vue'),
	name: 'finance-and-audit',
	
	meta: {
		guest: false,
		needsAuth: true
	}
},


// {
//   path: '/add-credit',
//   component: () => import('../components/partials/AddCredit.vue'),
//   name: 'add-credit',


//   meta: {
// 		guest: false,
// 		needsAuth: true
// 	}

// },

{
  path: '/view-credit',
  component: () => import('../components/partials/ViewCredit.vue'),
  name: 'view-credit',


  meta: {
		guest: false,
		needsAuth: true
	}

}, 


{
  path: '/edit-credit',
  component: () => import('../components/partials/EditCredit.vue'),
  name: 'edit-credit',


  meta: {
		guest: false,
		needsAuth: true
	}

},

{
  path: '/view-expenses',
  component: () => import('../components/partials/ViewExpenses.vue'),
  name: 'view-expenses',


  meta: {
		guest: false,
		needsAuth: true
	}

},

{
  path: '/edit-expenses',
  component: () => import('../components/partials/EditExpenses.vue'),
  name: 'edit-expenses',


  meta: {
		guest: false,
		needsAuth: true
	}

},


]
