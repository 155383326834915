export const getEventList = (state) => {
	// console.log('hi getter');
	return state.eventList
}


export const getCategoryList = (state) => {
	return state.categoryList
}

export const showAddEvent = (state)=> {
	return state.showAddEvent
}

export const  hideAddEvent = (state)=> {
	return state.hideAddEvent
}

export const showEditEvent = (state)=> {
	console.log('babababa');
	console.log(showEditEvent);
	return state.showEditEvent
}

export const hideEditEvent = (state)=> {
	return state.hideEditEvent
}

export const showDeleteEvent = (state) =>{
	return state.showDeleteEvent
}
export const  hideDeleteEvent = (state)=> {
	return state.hideDeleteEvent
}

export const  DeleteList = (state)=> {
	return state.DeleteList
}
export const getDataBeingEditForEvent = (state) => {
	return state.editEvent
}
export const getEditEventId = (state) => {
	return state.editEventId
}


export const getUpdateImageEventIdDelete = (state) => {
      return state.showImageEventDeleteModal
}
export const hideImageEventDeleteModal = (state) => {
      return state.hideImageEventDeleteModal
}

